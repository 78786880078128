
.Feedbackmain {
    background-color:#34495e41;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 100%;
    max-width: 600px;
    /* text-align: c; */
    margin-top: 10px;
  }
  .modal {
    display: flex;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 150%;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: white;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 0 auto;
    padding: 25px;
    border: 1px solid #888;
    align-self: center;
    width: 85%;
    font-size: 15px;
    max-width: 600px;
    border-radius: 10px;
    position: relative;
    /* border: 2px solid red; */
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .form-toggle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  }
  
  /* .form-toggle a {
    border: 3px solid red;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #34495e;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  } */
  
  .form-toggle button.active {
    background: linear-gradient(95.31deg, #56BBFF 1.59%, #55BBFF 1.6%, #061BEB 97.36%);
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content:center ;
  }
  
  .form {

    flex-direction: column;
  }
  
  .form.active {
    display: flex;
  }
  
  .form h2 {
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .form input {
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
  }
  
  .form button {
    padding: 10px;
    border: none;
    background: linear-gradient(95.31deg, #56BBFF 1.59%, #55BBFF 1.6%, #061BEB 97.36%);
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .form button:hover {
    background: linear-gradient(95.31deg, #56BBFF 1.59%, #55BBFF 1.6%, #061BEB 97.36%);}
  
  
  
    .form-container {
        background-color:transparent;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 20px;
        max-width: 600px;
        width:90%;
        justify-content: center;
        margin: 0 auto;
        /* border: 1px solid red; */
      }
      
      h1, h2 {
        color: #2c3e50;
      }
      
      label {
        font-weight: bold;
      }
      
      .form-container input[type="text"],
      .form-container input[type="email"],
      .form-container input[type="tel"],
      .form-containerinput[type="file"],
      .form-container textarea {
        width: 100%;
        padding: 10px;
        margin: 5px 0 15px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      }
      
      .form-container input:hover{
        border: 1px solid gray;
      
      }
      
     .form-container input[type="submit"] {
        width: 100%;
        background: linear-gradient(95.31deg, #56BBFF 1.59%, #55BBFF 1.6%, #061BEB 97.36%);  color: white;
        padding: 14px 20px;
        margin: 8px 0;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
      }
      
      .form-container input[type="submit"]:hover {
        background-color: #2980b9;
      }
      
      p {
        line-height: 1.6;
      }
      
      @media (max-width: 768px) {
        body{
          overflow-x: hidden;
        }
        .form-container {
            padding: 15px;
        }
      
        h1, h2 {
            font-size: 1.5em;
        }
      
        .form-container input[type="text"],
        .form-container input[type="email"],
        .form-container input[type="tel"],
        .form-container input[type="file"],
        .form-container textarea {
            padding: 8px;
        }
      
        .form-container input[type="submit"] {
            padding: 12px 18px;
            font-size: 14px;
        }
      }
      
      @media (max-width: 480px) {
        body{
          overflow-x: hidden;
        }
        .form-container {
            padding: 10px;
        }
      
        h1, h2 {
            font-size: 1.2em;
        }
      
        .form-container input[type="text"],
        .form-container input[type="email"],
        .form-container input[type="tel"],
        .form-container input[type="file"],
        .form-container textarea {
            padding: 6px;
        }
      
        .form-container input[type="submit"] {
            padding: 10px 16px;
            font-size: 12px;
        }
      }
      
  
  
  
  
  
  
  
   
    