/* Main Container */
.Main-Container {
    width: 100%;
    overflow: hidden;
}
 
/* Signup Image Container */
.signup-img {
    position: relative;
}
 
/* Signup Image */
.signup-img img:first-child {
    height: 100vh;
    width: 100%;
}
.robot{
    width: 60%;
}
@media screen and (max-width: 768px) {
    .signup-img img:first-child {
        height: auto;
    }
    .fieldtext{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
}
 
/* Signup Description */
.signup-descap p {
    color: #F8FAFC;
    font-size: 13px;
    line-height: 19px;
    margin-top: 20px;
    padding: 0 4px;
}
 
@media screen and (max-width: 576px) {
    .signup-descap p {
        font-size: 12px;
        margin-top: 7px;
    }
    .robot{
        width: auto;
    }
}
 
/* Detail Section */
.detail {
    position: absolute;
    width: 100%;
    background-image: linear-gradient(195.42deg, #56BBFF -63.22%, rgba(85, 187, 255, 0.21) -63.2%, #061BEB 87.54%);
    background-size: cover;
    bottom: 0;
    padding: 20px;
}
 
@media screen and (max-width: 576px) {
    .detail {
        padding: 10px 15px !important;
    }
}
 
/* Registration Heading */
.regis-heading p {
    font-size: 30px;
    font-weight: 700;
    color: #000;
}
 
@media screen and (max-width: 576px) {
    .regis-heading p {
        font-size: 26px;
    }
}
 
/* Registration Button */
.regis-heading button {
    width: auto;
    background: transparent;
    color: #000;
    font-weight: 700;
    padding: 0px 10px;
    font-size: 14px;
    border: none;
}
 
@media screen and (max-width: 576px) {
    .regis-heading button {
        padding: 0px 12px;
        font-size: 11px;
        border: 1.7px solid black;
        height: 41px;
    }
}
 
/* Social Buttons */
.social-btn {
    display: flex;
    flex-direction: row;
    border-color: #C4C4C4;
    background-color: #F8FAFC;
    height: 45px;
    padding: 10px 15px;
    border-radius: 10px;
    align-items: center;
    justify-content: flex-start;
    margin-right: 10px;
    margin: 0 auto;
    cursor: pointer;
}
 
.social-btn img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}
 
.social-btn label {
    margin-left: 10px;
    font-size: 20px;
    color: #474554;
}
 
@media screen and (max-width: 576px) {
    .social-btn {
        width: 40% !important;
        margin: 0px 13px !important;
        padding: 10px;
        justify-content: center !important;
    }
 
    .social-btn img {
        margin-left: 0 !important;
    }
 
    .social-btn label {
        font-size: 16px !important;
    }
}
 
/* Social Media */
.social-media {
    margin-top: 20px;
}
 
@media screen and (max-width: 576px) {
    .social-media {
        margin-top: 18px !important;
    }
}
 
/* Scrollbar */
.team\& {
    height: 300px !important;
    scrollbar-color: rgba(241, 90, 41, 1) rgba(226, 232, 240, 1);
    scrollbar-width: thin;
}
 
@media screen and (max-width: 576px) {
    .team\& {
        height: 55vh !important;
    }
}
 
/* Lists */
ol, ul {
    padding-left: 0rem !important;
}
 
/* Miscellaneous */
.or > div {
    width: 100%;
    margin-bottom: 8px;
}
 
.or h6 {
    margin: 0 15px;
    color: #a4a4a4;
}
 
 