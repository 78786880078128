.review-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
}
.reviewsmargin{
  margin-left: 50px;
}

.review {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.review-header img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.review-header p {
  margin: 0;
  font-family: Arial, sans-serif;
}

.review-header .name {
  font-weight: 600;
  font-size: 16px;
}

.review-header .date {
  color: #888;
  font-size: 14px;
}

.rating {
  color: #ffd700;
  font-size: 16px;
  margin-bottom: 10px;
}

.review-text {
  font-family: Arial, sans-serif;
  color: #555;
  font-size: 14px;
}
@media(max-width: 480px){
.reviewsmargin{
  margin-left: 15px;
}
}

