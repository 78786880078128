.earning-screen {
  display: flex;
  flex-direction: column;
}
.jobs-container p{
  text-align: center;
  margin-bottom: 0px;
}

.app {
  display: flex;
}

.jobs-container {
  flex: 1;
  padding: 20px;
  background-color: #fff;
}

.jobs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
/* 
.withdraw-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
} */

.withdraw-button {
  padding: 8px 15px; /* Smaller button size */
  background-color: #28a745; /* Updated to match the button color in the image */
  color: #fff; /* White text */
  border: none;
  border-radius: 5px;
  font-size: 14px; /* Adjusted font size */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.withdraw-button:hover {
  background-color: #218838; /* Slightly darker shade for hover effect */
}


.filter-section {
  margin-bottom: 20px;
}

.filter-dropdown {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.jobs-table {
  width: 100%;
  border-collapse: collapse;
}

.jobs-table th,
.jobs-table td {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.jobs-table .service-info {
  display: flex;
  align-items: center;
}

.jobs-table .user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.jobs-table .status {
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
}

.jobs-table .status.completed {
  background-color: #d4edda;
  color: #155724;
}

@media (max-width: 768px) {
  .jobs-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .withdraw-button {
    margin-top: 10px;
  }

  .jobs-table th,
  .jobs-table td {
    font-size: 14px;
    padding: 10px;
  }

  .jobs-table .user-image {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .jobs-container {
    padding: 10px;
    overflow-x:auto ;
  }

  .jobs-table th,
  .jobs-table td {
    font-size: 12px;
  }

  .withdraw-button {
    padding: 8px 15px;
    font-size: 14px;
  }
  .fa-2x {
    font-size: 20px !important;
}
}

/* CSS for Skeleton */

.Skeleton-jobs-screen{
  display: flex;
  flex-direction: column;
  width: 90rem;
  height: 30rem;
}

.Skeleton-jobs-screen .skeleton-item {
  background-color: #e0e0e0;
  animation: pulse 1s infinite ease-in-out;
}

.Skeleton-jobs-screen .skeleton-header {
  margin-top: 1rem;
  margin-left: 1rem;
  width: 60%;
  height: 60%;
  border-radius: 45px;
}

.Skeleton-jobs-screen .skeleton-content{
  width: 50%;
  height: 50%;
  border-radius: 6rem;
  margin-left: 1rem;
  margin-top: 1rem;
}


@keyframes pulse {
  0% {
      background-color: #e0e0e0;
  }

  50% {
      background-color: #d3d3d3;
  }

  100% {
      background-color: #e0e0e0;
  }
}


/* Media queries for responsiveness */


@media (max-width: 768px) {
  .Skeleton-jobs-screen{
    width: 40rem;
    height: 15rem;
  }
}

@media (max-width: 425px) {
  .Skeleton-jobs-screen{
    width: 15rem;
  }
}

@media (max-width: 375px) {
  .Skeleton-jobs-screen{
    width: 12rem;
  }
}