.centered-container777 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0;
  border-radius: 10px;
}

.card456 {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  max-width: 450px;
  text-align: center;
}

.card-header11 {
  position: relative;
  background: #fff;
  padding: 10px 20px;
}

.image-container {
  position: relative;
}

.profile-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: fill;
  cursor: pointer;
}

.file-input {
  display: none;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  padding: 5px;
  border: none;
  color: rgb(233, 30, 30);
  font-size: 18px;
}

.superhost-badge {
  position: absolute;
  top: 96px;
  right: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    95.31deg,
    #56bbff 1.59%,
    #55bbff 1.6%,
    #061beb 97.36%
  );
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 15px;
  width: 15px;
  font-weight: 600;
  height: 18px;
  cursor: pointer;
}

.card-body {
  padding: 10px 20px;
}

.card-body h2 {
  margin: 0;
  text-align: center;
  color: #333;
}

.info {
  text-align: left;
}

.info i {
  color: #ff385c;
  font-weight: 900;
}

.info p {
  margin: 5px 0;
  color: #666;
}

.edit-icon {
  display: none;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .card456 {
    width: 90vw;
    margin: 20px;
  }

  .card-header11 img {
    width: 120px;
    height: 120px;
    object-fit: fill;
  }

  .superhost-badge {
    display: none;
  }

  .edit-icon {
    display: none;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #56bbff;
    color: white;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
  }
}
