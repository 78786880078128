

@media screen and (max-width: 768px) {
    h3.list-pro {
        font-size: 23px !important;
        width: 100% !important;
        display: block !important;
    }
}




.wrapper {
    padding: 15px 20px;
    width: 200px;
    position: absolute;
}


    
    .wrapper .tooltip {
    background: #1496bb;
    bottom: 100%;
    color: #fff;
    display: block;
    /* left: -20px; */
    margin-bottom: 15px;
    opacity: 0;
    padding: 20px;
    pointer-events: none;
    position: absolute;
    width: 100%;
    -webkit-transform: translateY(10px);
      -moz-transform: translateY(10px);
      -ms-transform: translateY(10px);
       -o-transform: translateY(10px);
        transform: translateY(10px);
    -webkit-transition: all .25s ease-out;
      -moz-transition: all .25s ease-out;
      -ms-transition: all .25s ease-out;
       -o-transition: all .25s ease-out;
        transition: all .25s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
      -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
      -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
       -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    }

    .wrapper .tooltip {
        background: #535353;
        bottom: 100%;
        color: #fff;
        display: block;
        right: 115%;
        margin-bottom: 15px;
        opacity: 0;
        padding: 20px;
        pointer-events: none;
        position: absolute;
        width: 340px;
        transform: translateY(10px);
        transition: all .25s ease-out;
        -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        bottom: -80px;
    }
    
    /* This bridges the gap so you can mouse into the tooltip without it disappearing */
    .wrapper .tooltip:before {
    bottom: -20px;
    content: " ";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
    }
    
    /* CSS Triangles - see Trevor's post */
    .wrapper .tooltip:after {
        border-left: solid transparent 10px;
        border-right: solid transparent 10px;
        border-top: solid #535353 10px;
        bottom: 78px;
        content: " ";
        height: 0;
        right: -14px;
        margin-left: -13px;
        position: absolute;
        width: 0;
        transform: rotate(-93deg);
    }
    .wrapper:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
       -o-transform: translateY(0px);
        transform: translateY(0px);
    }
    
    /* IE can just show/hide with no transition */
    .lte8 .wrapper .tooltip {
    display: none;
    }
    
    .lte8 .wrapper:hover .tooltip {
    display: block;
    }