.navbar-nav li.nav-item a {
    font-weight: 500;
    color: #000;
}
/* .navbar-nav li.nav-item a:hover{
    color: #F15A29;
    position: relative;
} */
.navbar-nav li.nav-item a{
    position: relative;
}
/* .navbar-nav li.nav-item a:hover::after {
    content: '';
    width: 100%;
    height: 5px;
    display: block;
    background-color: #F15A29;
} */
/* .btn-white button:hover{
    background: transparent;
} */

@media screen and (max-width: 768px) {
    .main-cont {
        width: 100% !important;
        flex-wrap: nowrap !important;
        padding: 0;
    }
    .main-cont button {
        width: auto !important;
    }
    
    
}