body{
  overflow-x: hidden;
}
 
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
  left: -352px;
  top: 16px;
  z-index: 50;
}
 
.dropdown-content {
  position: absolute;
  background-color: white;
  border-radius: 10px;
  min-width: 360px;
  max-width: 100%;
  max-height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  box-sizing: border-box;
  margin-top: 1rem;
  font-family: Arial, sans-serif;
}
 
 
.notch::before{
  content: "";
  position: absolute;
  top: 8px;
  left: 337px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #000;
}
 
 
.notification p {
  color: #000;
  font-size: 14px;
  margin: 5px 2px;
  padding: 0 10px;
  white-space: normal;
  overflow-wrap: break-word;
}
 
.notification p:hover {
  color: blue;
  cursor: pointer;
  transition: color 0.3s ease;
}
 
.dropdown-content h3 {
  display: flex;
  justify-content: center;
  padding: 10px;
  background: linear-gradient(95.31deg, #333434d1 1.59%, #000 97.36%);
  border-radius: 10px 10px 0 0;
  color: #fff;
  font-size: 18px;
  margin: 0;
}
 
 
/* For Read and Unread Buttons */
.notification-tabs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: #f5f5f5
}
 
.notification-tabs .tab-button {
  flex: 1;
  text-align: center;
  padding: 8px 0;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  border-right: 1px solid #ddd;
}
 
 
.notification-tabs .tab-button:last-child {
  border-right: none;
}
 
.notification-tabs .tab-button.active {
  background-color: #007bff;
  color: #fff;
}
 
 
/* Notifications List */
.notifications-list {
  display: flex;
  margin-top: 10px;
}
 
.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 1px;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #ddd;
}
 
.notification.read p {
  font-weight: normal; /* Dim read notifications */
  color: #888; /* Gray color for read notifications */
}
 
.notification.unread p {
  font-weight: bold; /* Bold unread notifications */
  color: #000; /* Black color for unread notifications */
}
 
.notification:last-child {
  border-bottom: none;
}
 
/* Blinking red dot for unread notifications */
.blinking-dot {
  position: relative;
}
 
.blinking-dot::after {
  content: "";
  position: absolute;
  top: 4px;
  right: 48px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  animation: blink 2s infinite;
}
 
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
 
 
 
/* Load More Button */
.load-more {
  text-align: center;
  padding: 10px;
  border-top: 1px solid #ddd;
  cursor: pointer;
  font-size: 14px;
  background-color: #fff;
  z-index: 10;
}
 
.load-more:hover {
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}


/* CSS for Skeleton */
.Skeleton-notifications-container{
  display: flex;
  flex-direction: column;
  width: 20rem;
  height: 10rem;
  max-height: 20rem;
  margin: 1rem;
}

.Skeleton-notifications-container .skeleton-head{
  width: 18rem;
  height: 3rem;
  margin-top: 5%;
  margin-left: 1rem;
  animation: pulse 1s infinite ease-in-out;
}

.Skeleton-notifications-container .skeleton-header{
  width: 18rem;
  height: 3rem;
  margin-left: 1rem;
  animation: pulse 1s infinite ease-in-out;
}
/* Booking Headers */
.booking-headers {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-weight: bold;
  border-bottom: 1px solid rgba(128, 128, 128, 0.4);
}
 
.booking-headers span {
  width: 30%;
  text-align: left;
}
 
/* Booking Items */
.booking-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}
 
/* Adjustments for different statuses */
.status-confirmed {
  color: green;
}
 
.status-pending {
  color: orange;
}
 
.status-cancelled {
  color: red;
}
 
.booking-title,
.booking-status,
.booking-date {
  width: 30%;
  text-align: left;
}
 
/* Responsive Design */
@media (max-width: 768px) {
  .dropdown-content {
    position: static;
    width: 100%;
  }
 
  .booking-headers span,
  .booking-item span {
    width: 100%;
    /* Stack items on smaller screens */
  }
 
  .booking-item {
    display: block;
  }
}
 