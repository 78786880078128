/* Container for the input field and button */
.message-input-container1 {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    padding: 10px;
  }
  
  /* Styling for the input field */
  .message-input-field {
    width: 100%;
    padding: 12px;
    margin-right: 10px;
    font-size: 16px;
    outline: none;
    border: none;
    background: transparent;
    cursor: text
  }
  .message-input-field {
    cursor: text; /* Always show text cursor */
  }
  /* Styling for the send button */
  .send-button ,.file-options-button{
    background: transparent !important;
    color: rgb(69, 67, 227);
    padding: 12px 20px;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: auto;
  }
  
  .send-button:hover {
    background-color: #218838;
  }

  /* Emoji button styling */
.emoji-button {
  background: none;
  border: none;
  cursor: pointer;
  color: rgb(69, 67, 227);
  font-size: 20px;
  margin-right: 10px;
  width: auto;
}

/* Emoji picker container */
.emoji-picker-container {
  position: absolute;
  bottom: 60px;
  left: 15px;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
}
