.headingmian h1 {
  font-size: 32px;
  font-weight: 700;
  color: #000;
  margin: 35px 20px;
}
.feedback-button {
  position: fixed;
  background: black;
  border: 1px solid;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  margin-right: -22px;
  cursor: pointer;
  /* background: none; */
  right: 0;
  bottom: 50px;
  z-index: 1000;
  transform: rotate(-90deg); /* Rotate button 90 degrees clockwise */
}

.carousel-list {
  max-width: 100%;
}

.btn-sub {
  width: 91.5%;
}

.btn-sub button {
  width: auto;
  padding: 0 40px;
  background: rgba(241, 90, 41, 1);
  color: #fff;
  font-weight: 500;
  float: right;
}

.headingmian h1 {
  font-size: 24px;
  margin: 20px 20px;
}

.check-in {
  display: contents;
}

.btn-top a button {
  width: 8.5rem !important;
  margin: 0 !important;
}

.advance-filter-button button {
  width: auto !important;
  padding: 0 20px !important;
}
/* Styles for larger screens */
@media (min-width: 768px) {
  /* Example: Set a minimum width for the Crousel */
  .carousel-container {
    min-width: 50%;
    margin: 0 auto;
  }

  /* Example: Adjust font size for the HomeHeader */
  .home-header {
    font-size: 24px;
  }
  .feedback-button {
    position: fixed;
    width: 120;
    /* background: none; */
    right: 0;
    bottom: 50px;
    z-index: 1000;
    transform: rotate(-90deg); /* Rotate button 90 degrees clockwise */
  }

  /* Add other responsive styles for larger screens here */
}

/* Styles for smaller screens */
@media (max-width: 767px) {
  /* Example: Center the Crousel and set its width to 100% */
  .carousel-container {
    width: 100%;
    margin: 0 auto;
  }

  /* Example: Adjust font size for the HomeHeader */
  .home-header {
    font-size: 20px;
  }
  .content-section {
    background-size: cover;
  }
  .advance-filter-button button {
    width: 100% !important;
  }
  .btn-top {
    text-align: center;
    margin-top: 15px;
  }
  /* .hind-bs{
  display: none;
} */
}

@media screen and (max-width: 576px) {
  .slider-box {
    width: 100% !important;
  }
  .main-title {
    font-size: 26px;
    margin-bottom: 1.4rem;
    margin-left: 1.1rem;
    padding: 0;
    margin-top: 0;
  }
  .filter-container {
    height: auto;
  }
  .filter-item {
    margin-bottom: 10px;
    margin-right: 0px;
    border-left: none !important;
    box-sizing: border-box;
    padding-left: 10px;
  }
  .check-in {
    display: flex;
  }
  .input {
    padding: 5px;
  }
  .filter-container .filter-item:last-child {
    margin: 10px 0px 0 0;
  }
  .Email-support {
    flex-wrap: wrap;
  }
  .emailsupt {
    width: 100% !important;
    display: block !important;
    padding-left: 35px;
    border-bottom: 1px solid #323232;
    border-right: 0 !important;
    padding-bottom: 15px;
  }
  .support {
    width: 100% !important;
    margin-left: 0 !important;
    padding: 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .vedwetth img {
    max-width: 200px; /* Adjust for tablets */
  }

  .hostregfont {
    font-size: 0.9rem; /* Slightly smaller text */
  }
}

@media screen and (max-width: 480px) {
  .vedwetth img {
    max-width: 150px; /* Adjust for mobile */
  }

  .hostregfont {
    font-size: 0.8rem; /* Further smaller text */
  }
}


/* CSS for Skeleton */

.Skeleton-homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.Skeleton-homepage .skeleton-item {
  background-color: #e0e0e0;
  animation: pulse 1s infinite ease-in-out;
}

.Skeleton-homepage .skeleton-country {
  margin-top: 2rem;
  margin-left: 34rem;
  width: 54%;
  height: 13%;
  border-radius: 10px;
}

.Skeleton-homepage .skeleton-search {
  margin-left: 40rem;
  width: 40%;
  height: 8%;
  border-radius: 10px;
}

.Skeleton-homepage .skeleton-destinations {
  margin-left: 40rem;
  width: 40%;
  height: 8%;
  border-radius: 10px;
}

.Skeleton-homepage .skeleton-near-you {
  margin-left: 40rem;
  width: 40%;
  height: 8%;
  border-radius: 10px;
}

.Skeleton-homepage .skeleton-footer {
  margin-left: 40rem;
  width: 40%;
  height: 8%;
  border-radius: 10px;
}

@keyframes pulse {
  0% {
      background-color: #e0e0e0;
  }

  50% {
      background-color: #d3d3d3;
  }

  100% {
      background-color: #e0e0e0;
  }
}


/* Media queries for responsiveness */
/* 
@media (min-width: 2560px) {
  .Skeleton-homepage {
      width: 100em;
  }

  .Skeleton-homepage .skeleton-box {
      width: 60%;
      height: 40%;
      margin-left: 46rem;
  }

  .Skeleton-homepage .skeleton-content {
      margin-left: 56rem;
      height: 8%;
  }
}

@media (max-width: 1440px) {
  .Skeleton-homepage {
      width: 70em;
  }
}

@media (max-width: 1200px) {
  .Skeleton-homepage {
      width: 40em;
  }

  .Skeleton-homepage .skeleton-box {
      width: 80%;
      margin-left: 29rem;
      height: 8%;
  }

  .Skeleton-homepage .skeleton-content {
      margin-left: 37rem;
  }
}

@media (max-width: 1024px) {
  .Skeleton-homepage {
      width: 40em;
  }

  .Skeleton-homepage .skeleton-box {
      width: 80%;
      margin-left: 25rem;
      height: 8%;
  }

  .Skeleton-homepage .skeleton-content {
      margin-left: 33rem;
  }
}

@media (max-width: 768px) {
  .Skeleton-homepage {
      width: 40em;
  }

  .Skeleton-homepage .skeleton-box {
      margin-left: 12rem;
  }

  .Skeleton-homepage .skeleton-content {
      margin-left: 20rem;
  }
}

@media (max-width: 425px) {
  .Skeleton-homepage {
      width: 26em;
  }

  .Skeleton-homepage .skeleton-box {
      width: 60%;
      margin-left: 5rem;
      height: 5%;
  }

  .Skeleton-homepage .skeleton-content {
      width: 34%;
      margin-left: 8rem;
      height: 5%;
  }
}

@media (max-width: 375px) {
  .Skeleton-homepage {
      width: 20em;
  }

  .Skeleton-homepage .skeleton-box {
      width: 50%;
      margin-left: 7rem;
      height: 3%;
  }

  .Skeleton-homepage .skeleton-content {
      width: 30%;
      margin-left: 9rem;
      height: 3%;
  }
} */
