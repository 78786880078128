/* styles.css */
.userbooking{
    /* display: none; */
 
}
.contentStyle{
    border: 2px solid blue;
}
.popups{
    border:3px solid red;
}
#calanderpading{
    padding-left: 50px;
}
/* Hide button on screens smaller than 600px */
/* styles.css */
@media (min-width: 771px) {
    .userbooking {
        display: none;
    }
}
@media (max-width: 480px) {
    #calanderpading{
        padding-left: 10px;
    }
   
}
 
 
 
 
 
 