.property-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  margin-bottom: 50px;
}
input[disabled] {
  cursor: not-allowed;
}
.service-item {
  background: #F3F4F6;
  border-radius: 5px;
  color: darkgray;
  font-size: 14px;
  padding: 10px 15px;
  margin-right: 10px;
  list-style: none;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}
 
.service-item.active {
  background:#0000;
  color: white;
}
 
.property-form h1 {
  text-align: center;
  margin-bottom: 20px;
}
 
.property-form {
  display: grid;
  gap: 15px;
}
 
.form-group {
  margin-bottom: 15px;
}
 
.form-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}
 
.property-form input,
.property-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}
 
.property-form input[type="number"] {
  -moz-appearance: textfield; /* Hide spin buttons in Firefox */
}
 
.property-form input::placeholder {
  color: #888;
}
 
.property-form button {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
 
.property-form button:hover {
  background-color: #0056b3;
}
 
.location-container {
  display: flex;
  gap: 10px;
}
 
.location-container input {
  flex: 1;
}
 
.single-line {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
 
.single-line .form-field {
  flex: 1;
  min-width: 120px;
}
 
.single-line label {
  display: block;
}
 
.single-line input {
  width: 100%;
}
 
.extra-services {
  display: flex;
  flex-wrap: wrap;
}
.addcencel button{
  width: 30%;
  margin-top: 20px;
}
 
.service-ex  {
  background: #F3F4F6;
  width: 20%;
  cursor: pointer;
  height: 45px;
  border-radius: 5px;
  text-align: center;
  color: #6B7280;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-ex  p{
  white-space: nowrap;
}
 
.add-service-button {
  margin-left: 10px;
  width: auto;
  border-radius: 25px;
  border-width: 0.1px;
  border-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid gray;
  background-color: #FFFFFF;
  color: #000000;
  cursor: pointer;
}
 
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 80%;
  height: 25%;
  display: flex;
  flex-direction: column;
}
 
.modal button {
  margin-top: 10px;
}
 
@media (max-width: 600px) {
  .property-form-container {
    padding: 10px;
  }
 
  .single-line {
    flex-direction: column;
  }
 
  .single-line .form-field {
    width: 100%;
  }
}
 
/* General form container styling */
.property-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}
 
/* Section header styling */
.property-form-container h2 {
  margin-top: 20px;
  color: #333;
  font-size: 1.5rem;
  border-bottom: 2px solid #f15a29;
  padding-bottom: 10px;
}
 
/* Extra Services Container */
.extra-services {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
 
.extra-services div p {
  background-color: #f3f4f6;
  color: #333;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s ease;
}
 
.extra-services div p:hover {
  background-color: #f15a29;
  color: #fff;
  cursor: pointer;
}
 
.extra-services div p.selected {
  background-color: #f15a29;
  color: #fff;
}
 
/* Button styling */
#id600{
  background-color: #f15a29;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}
 
#id600:hover {
  background-color: #d14920;
}
 
/* Selected Extra Service Form */
.form-group h3 {
  color: #f15a29;
  margin-bottom: 10px;
}
 
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}
 
.form-group textarea {
  resize: none;
}
 
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
 
/* Modal Box */
.modal {
  background: white;
  padding: 20px;
  width: 350px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}
 
.modal h3 {
  color: #333;
  margin-bottom: 15px;
}
 
.modal input {
  padding: 8px 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;
  margin: 0 auto;
}
 
.modal button {
  margin: 5px;
  margin-top: 20px;
  background-color: #f15a29;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
}
 
.modal button:hover {
  background-color: #d14920;
}
.profile-popup {
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
 
.profile-popup img {
  cursor: pointer;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 2px solid #0F172A;
}
 
.profile-popup button {
  font-size: 18px;
  color: #0F172A;
}
 
.profile-popup input[type="file"] {
  display: none;
}
 
.profile-popup p {
  font-size: 14px;
  color: #0F172A;
}
 
 