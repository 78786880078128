.custom-chat-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eaeaea;
  }
  .custom-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .custom-chat-name {
    font-size: 14px;
    font-weight: bold;
  }
  
  .custom-last-message {
    font-size: 12px;
    color: #888;
  }
  .custom-chat-item.active {
    background-color: #f0f0f0;
    border-left: 4px solid #007bff;
  }
  @media (max-width: 480px) {
  .flex-1 h4{
    font-size: 10px !important;
  }
  .custom-avatar {
    width: 25px;
    height: 25px;
    margin-right: 1px;
  }
  .custom-chat-item {
    padding: 2px;
  }
}