
  
  .intro {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #333;
    padding: 20px;
    width: 100%;
  }
  .cookieshedding{
    color: #0073e6;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }
   .cookiesintro{
    color: #0073e6;
    font-size: 24px;
    text-align: left;
    width: 100%;
  }
  
  p {
    color: #333;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: justify;
    width: 100%;
  }
  
  /* Add some space between sections */
  h2 + p {
    margin-top: -10px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .intro {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .term-condition-container {
      padding: 15px;
    }
  
    h2 {
      font-size: 22px;
      text-align: left;
    }
  
    p {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .term-condition-container {
      padding: 10px;
    }
  
     h2 {
      font-size: 20px;
    }
  
     p {
      font-size: 12px;
    }
    li{
      font-size:12px ;
    }
  }
  