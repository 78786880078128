/* CSS for Skeleton */

.Skeleton-support {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100rem;
    height: 30rem;
}

.Skeleton-support .skeleton-item {
    background-color: #e0e0e0;
    animation: pulse 1s infinite ease-in-out;
}

.Skeleton-support .skeleton-box {
    margin-top: 2rem;
    margin-left: 34rem;
    width: 54%;
    height: 13%;
    border-radius: 10px;
}

.Skeleton-support .skeleton-content {
    margin-left: 40rem;
    width: 40%;
    height: 8%;
    border-radius: 10px;
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }

    50% {
        background-color: #d3d3d3;
    }

    100% {
        background-color: #e0e0e0;
    }
}


/* Media queries for responsiveness */

@media (min-width: 2560px) {
    .Skeleton-support {
        width: 100em;
    }

    .Skeleton-support .skeleton-box {
        width: 60%;
        height: 40%;
        margin-left: 46rem;
    }

    .Skeleton-support .skeleton-content {
        margin-left: 56rem;
        height: 8%;
    }
}

@media (max-width: 1440px) {
    .Skeleton-support {
        width: 70em;
    }
}

@media (max-width: 1200px) {
    .Skeleton-support {
        width: 40em;
    }

    .Skeleton-support .skeleton-box {
        width: 80%;
        margin-left: 29rem;
        height: 8%;
    }

    .Skeleton-support .skeleton-content {
        margin-left: 37rem;
    }
}

@media (max-width: 1024px) {
    .Skeleton-support {
        width: 40em;
    }

    .Skeleton-support .skeleton-box {
        width: 80%;
        margin-left: 25rem;
        height: 8%;
    }

    .Skeleton-support .skeleton-content {
        margin-left: 33rem;
    }
}

@media (max-width: 768px) {
    .Skeleton-support {
        width: 40em;
    }

    .Skeleton-support .skeleton-box {
        margin-left: 12rem;
    }

    .Skeleton-support .skeleton-content {
        margin-left: 20rem;
    }
}

@media (max-width: 425px) {
    .Skeleton-support {
        width: 26em;
    }

    .Skeleton-support .skeleton-box {
        width: 60%;
        margin-left: 5rem;
        height: 5%;
    }

    .Skeleton-support .skeleton-content {
        width: 34%;
        margin-left: 8rem;
        height: 5%;
    }
}

@media (max-width: 375px) {
    .Skeleton-support {
        width: 20em;
    }

    .Skeleton-support .skeleton-box {
        width: 50%;
        margin-left: 7rem;
        height: 3%;
    }

    .Skeleton-support .skeleton-content {
        width: 30%;
        margin-left: 9rem;
        height: 3%;
    }
}