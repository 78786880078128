.btn-white button {
  background: transparent;
  margin-left: 20px;
}
/* .pro-icon{
} */
.user-pro {
  width: 70%;
}
.box-profile {
  width: 100%;
}
#profilepop {
  width: 20%;
}
#myprofo {
  width: 10%;
}
@media (max-width: 480px) {
  .btn-white button {
    background: transparent;
    margin-left: 0;
  }
  #profilepop {
    width: 60% !important;
  }
  #myprofo {
    width: 35%;
  }
}
.btn-white button:hover {
  background-color: transparent;
}
.table-Page {
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border-top: 1px solid rgba(229, 231, 235, 1);
}

@media screen and (max-width: 768px) {
  .user-pro {
    width: 100% !important;
    flex-wrap: wrap;
    margin-bottom: 0 !important;
  }
  .user-pro img.img-pro {
    width: 80px !important;
    height: 80px !important;
    margin-bottom: 20px;
  }
  .pro-icon label {
    font-size: 18px !important;
  }
  .btn-white {
    width: 100% !important;
  }
  .btn-white button {
    width: 100% !important;
    margin-bottom: 0;
  }
  .box-profile {
    flex-wrap: wrap;
  }
  .booking-box {
    height: auto !important;
    margin-bottom: 30px;
  }
}

/* Modal Overlay */
.modal-overlays {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal Container */
.modal-containers {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 20%;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Modal Header */
.modal-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-headers h2 {
  font-size: 18px;
  color: #0f172a;
  font-weight: 600;
  white-space: nowrap;
}

.closebtns {
  border: none;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
  color: red;
  font-weight: bold;
  text-align: right;
}

/* Modal Content */
.modal-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-images {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 1px solid #0f172a;
}

.edit-icons {
  position: absolute;
  top: 10rem;
  left: 12rem;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #fff;
  background-color: #0f172a;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.modal-contents p {
  margin-bottom: 0.3rem;
}

 /* CSS for Skeleton */

 .skeleton-user-profile {
  display: flex;
  align-items: center;
  padding: 10px;
}

.skeleton-user-profile .skeleton-item {
  background-color: #e0e0e0;
  animation: pulse 1s infinite ease-in-out;
}

.skeleton-user-profile .skeleton-pp {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 1.5rem;
}

.skeleton-user-profile .skeleton-db {
  width: 150px;
  height: 40px;
  border-radius: 4px;
  margin-left: 80rem;
}

.skeleton-user-profile .skeleton-dn {
  width: 140px;
  height: 30px;
  border-radius: 4px;
  margin-left: 2rem;
}


.skeleton-user-profile .skeleton-navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.skeleton-user-profile .skeleton-navbar-items {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #d3d3d3;
  }
  100% {
    background-color: #e0e0e0;
  }
}

/* Media Queries */

@media (min-width: 2560px) {
  .skeleton-user-profile .skeleton-db {
    margin-left: 120rem;
  }
}
@media (max-width: 1440px) {
  .modal-containers {
    width: 27%;
  }
  .edit-icons {
    position: absolute;
    top: 10rem;
    left: 14rem;
  }
  
  .skeleton-user-profile .skeleton-db {
    margin-left: 55rem;
  }
}

@media (max-width: 1200px) {
  .modal-containers {
    width: 32%;
  }
  .edit-icons {
    position: absolute;
    top: 10rem;
    left: 14rem;
  }
  .skeleton-user-profile .skeleton-db {
    margin-left: 44rem;
  }
}

@media (max-width: 1024px) {
  .modal-containers {
    width: 38%;
  }
  .edit-icons {
    position: absolute;
    top: 10rem;
    left: 15rem;
  }

  .skeleton-user-profile .skeleton-db {
    margin-left: 32rem;
  }
}

@media (max-width: 768px) {
  .modal-containers {
    width: 50%;
    padding: 15px;
  }
  .edit-icons {
    position: absolute;
    top: 10rem;
    left: 14rem;
  }
  .skeleton-user-profile .skeleton-db {
    margin-left: 18rem;
  }
}

@media (max-width: 480px) {
  .modal-containers {
    width: 70%;
    padding: 10px;
  }

  .edit-icons {
    position: absolute;
    top: 9rem;
    left: 8rem;
  }

  .modal-images {
    width: 100px;
    height: 100px;
  }

  .skeleton-user-profile .skeleton-pp {
    width: 30px;
    height: 30px;
    margin-left: 1rem;
}

.skeleton-user-profile .skeleton-db {
  width: 50px;
  height: 20px;
  margin-left: 12rem;
}

.skeleton-user-profile .skeleton-dn {
  width: 50px;
  height: 20px;
  margin-left: 1rem;
}
}

@media (max-width: 375px) {
  .modal-containers {
    width: 70%;
    padding: 10px;
  }

  .edit-icons {
    top: 7.5rem;
    left: 7.5rem;
    font-size: 20px;
  }

  .modal-images {
    width: 80px;
    height: 80px;
  }

  .modal-headers h2 {
    font-size: 14px;
  }

  .modal-contents p {
    font-size: 12px;
  }

  .skeleton-user-profile .skeleton-db {
    margin-left: 8rem;
  }
}

@media (max-width: 320px) {
  .edit-icons {
    top: 7.5rem;
    left: 7rem;
    font-size: 20px;
  }

  .skeleton-user-profile .skeleton-db {
    margin-left: 6rem;
  }
}
