.main-message-container {
  display: flex;
  height: 100vh;
  background-color: #f3f3f3;
}

.chat-list-sidebar {
  width: 25%;
  background-color: #ffffff;
  border-right: 1px solid #ddd;
  overflow-y: auto;
}

.chat-list-sidebar::-webkit-scrollbar {
  width: 8px;
}

.chat-list-sidebar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

/* Messages Panel Styles */
.messages-panel {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}

.no-chat-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #888;
  font-size: 18px;
  font-style: italic;
}

.messages-panel-content {
  flex-grow: 1;
  padding: 15px;
  overflow-y: auto;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
}
.messages-panel-content::-webkit-scrollbar{
  display: none;
}

@media (max-width: 480px) {
  .chat-list-sidebar {
    width: 30%;
    background-color: #ffffff;
    border-right: 1px solid #ddd;
    overflow-y: auto;
  }
}