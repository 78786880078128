.Passport-ID{
    padding: 1.5rem 0;
    padding-left: 40px;
}
@media screen and (max-width: 576px) {
    .Passport-ID {
        width: 100% !important;
    }
    label.id-pass {
        width: auto !important;
        margin-top: 20px;
    }
    .Passport-ID h4{
        font-size: 22px;
    }
    .Country-opt {
        width: 100% !important;
    }
    .Passport-ID{
        padding: 1.5rem 0;
    }
}
 