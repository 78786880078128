.Skeleton-host-booking-list{
    display: flex;
    flex-direction: column;
    width: 100rem;
    height: 100rem;
    margin-left: 5rem;
}

.Skeleton-host-booking-list .skeleton-item {
    background-color: #e0e0e0;
    animation: pulse 1s infinite ease-in-out;
}

.Skeleton-host-booking-list .skeleton-header {
    width: 72%;
    height: 5%;
    /* margin : top right bottom left*/
    margin: 1rem 0rem 0rem 12rem;
    border-radius: 1rem;
}

.Skeleton-host-booking-list .skeleton-heading {
    width: 88%;
    height: 2%;
    margin: 8px 0;
    margin-top: 1rem;
    margin-left: 5rem;
    border-radius: 1rem;
}
.Skeleton-host-booking-list .skeleton-content{
    width: 60%;
    height: 3%;
    margin: 8px 0;
    border-radius: 1rem;
    margin-left: 17rem;
    margin-top: 3rem;
}
.Skeleton-host-booking-list .skeleton-page {
    width: 15%;
    height: 4%;
    margin: 8px 0;
    border-radius: 1rem;
    margin-left: 37rem;
    margin-top: 1rem;
}


@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }

    50% {
        background-color: #d3d3d3;
    }

    100% {
        background-color: #e0e0e0;
    }
}


/* Media queries for responsiveness */

@media (min-width: 2560px) {
    .Skeleton-host-booking-list{
        margin-left: 18rem;
        height: 60rem;
    }
    .Skeleton-host-booking-list .skeleton-header {
        width: 72%;
        height: 10%;
        margin-left: 11rem;
    }

    .Skeleton-host-booking-list .skeleton-heading {
        margin-top: 2rem;
        height: 5%;
        margin-left: 4rem;
    }

    .Skeleton-host-booking-list .skeleton-content{
        margin-top: 1rem;
        height: 10%;
    }

    .Skeleton-host-booking-list .skeleton-page {
        width: 15%;
        margin-left: 39rem;
        margin-top: 1rem;
    }
}

@media (max-width: 1440px) {
    .Skeleton-host-booking-list{
        margin-left: 0rem;
        height: 40rem;
    }
    .Skeleton-host-booking-list .skeleton-header {
        width: 72%;
        height: 10%;
        margin-left: 11rem;
    }

    .Skeleton-host-booking-list .skeleton-heading {
        margin-top: 2rem;
        height: 5%;
    }

    .Skeleton-host-booking-list .skeleton-content{
        margin-top: 1rem;
        height: 10%;
    }

    .Skeleton-host-booking-list .skeleton-page {
        width: 15%;
        margin-left: 34rem;
        margin-top: 1rem;
    }
}
@media (max-width: 1200px) {
    .Skeleton-host-booking-list{
        margin-left: 0rem;
        height: 40rem;
    }

    .Skeleton-host-booking-list .skeleton-header {
        width: 70%;
        margin-left: 12rem;
    }

    .Skeleton-host-booking-list .skeleton-heading {
        margin-left: 7rem;
    }

    .Skeleton-host-booking-list .skeleton-content{
        width: 60%;
        margin-left: 15rem;
        margin-top: 3rem;
    }
    .Skeleton-host-booking-list .skeleton-page {
        width: 15%;
        margin-left: 29rem;
        margin-top: 1rem;
    }
}
@media (max-width: 1024px) {
    .Skeleton-host-booking-list{
        margin-left: 0rem;
    }

    .Skeleton-host-booking-list .skeleton-header {
        /* margin : top right bottom left*/
        margin: 1rem 0rem 0rem 9rem;
    }
    
    .Skeleton-host-booking-list .skeleton-heading {
        width: 88%;
        margin-left: 5rem;
    }

    .Skeleton-host-booking-list .skeleton-content{
        width: 60%;
        height: 5%;
        margin-left: 12rem;
        margin-top: 2rem;
    }
    .Skeleton-host-booking-list .skeleton-page {
        width: 15%;
        margin-left: 24rem;
        margin-top: 1rem;
    }
}
@media (max-width: 768px) {
    .Skeleton-host-booking-list{
        margin-left: 0rem;
    }
    
    .Skeleton-host-booking-list .skeleton-header {
        width:30% ;
        /* margin : top right bottom left*/
        margin: 1rem 0rem 0rem 9rem;
    }
    
    .Skeleton-host-booking-list .skeleton-heading {
        width: 40%;
        margin-left: 5rem;
    }

    .Skeleton-host-booking-list .skeleton-content{
        width: 24%;
        height: 5%;
        margin-left: 12rem;
        margin-top: 2rem;
    }
    .Skeleton-host-booking-list .skeleton-page {
        width: 6%;
        margin-left: 22rem;
        margin-top: 1rem;
    }
}
@media (max-width: 425px) {
    .Skeleton-host-booking-list{
        margin-left: 0rem;
    }
    
    .Skeleton-host-booking-list .skeleton-header {
        width:10% ;
        /* margin : top right bottom left*/
        margin: 1rem 0rem 0rem 9rem;
    }
    
    .Skeleton-host-booking-list .skeleton-heading {
        width: 18%;
        margin-left: 5rem;
    }

    .Skeleton-host-booking-list .skeleton-content{
        width: 7%;
        height: 5%;
        margin-left: 11rem;
        margin-top: 2rem;
    }
    .Skeleton-host-booking-list .skeleton-page {
        width: 3%;
        margin-left: 13rem;
        margin-top: 1rem;
    }
}

@media (max-width: 375px) {
    .Skeleton-host-booking-list{
        margin-left: 0rem;
    }
    
    .Skeleton-host-booking-list .skeleton-header {
        width:14% ;
        /* margin : top right bottom left*/
        margin: 1rem 0rem 0rem 6rem;
    }
    
    .Skeleton-host-booking-list .skeleton-heading {
        width: 18%;
        margin-left: 4rem;
    }

    .Skeleton-host-booking-list .skeleton-content{
        width: 7%;
        height: 5%;
        margin-left: 10rem;
        margin-top: 2rem;
    }
    .Skeleton-host-booking-list .skeleton-page {
        width: 3%;
        margin-left: 12rem;
        margin-top: 1rem;
    }
}
@media (max-width: 320px) {
    .Skeleton-host-booking-list{
        margin-left: 0rem;
    }
    
    .Skeleton-host-booking-list .skeleton-header {
        width:10% ;
        /* margin : top right bottom left*/
        margin: 1rem 0rem 0rem 6rem;
    }
    
    .Skeleton-host-booking-list .skeleton-heading {
        width: 16%;
        margin-left: 3rem;
    }

    .Skeleton-host-booking-list .skeleton-content{
        width: 7%;
        height: 5%;
        margin-left: 8rem;
        margin-top: 2rem;
    }
    .Skeleton-host-booking-list .skeleton-page {
        width: 3%;
        margin-left: 10rem;
        margin-top: 1rem;
    }
}