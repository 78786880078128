.rdrDayDisabled {
    background-color: hsla(21, 94%, 51%, 0.627);
    border-radius: 50%;
    cursor:not-allowed !important;
    height: 30px;
    width: 30px;
}
.rdrDayDisabled .rdrDayNumber span {
    color: #fff !important;
}
.rdrDays {
    display: flex;
    flex-wrap: wrap;
    gap:5px
}
.rdrDay {
    color: transparent !important;
}