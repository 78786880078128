.container{
    padding: 20;
}
#zeropadd{
    padding-left: 45px;
}
#paddingzero{
    overflow-y: hidden;
 }
 .side-icon{
     display: flex;
     justify-content: space-between;
     width: 35%;
 }
 .dotone{
     width:0.625rem;
     height: 0.625rem;
     margin-right: -0.125rem;
     margin-bottom: 0.625rem;
     border: none;
     border-radius: 0.625rem;
     margin-left: -0.29rem;
     margin-top: 25px;
     position:absolute;
 }
 
 @media screen and (max-width: 768px) {
     .side-icon{
         width: 40%;
     }
     .dotone{
         margin-top: 18px;
     }
     #paddingzero{
         padding: 0;
     }
     .side-profile{
 margin-right:0 ;
     }
 }
 @media screen and (width: 768px) {
     .dotone{
         margin-left: 2.7rem;
     }
     #zeropadd{
         padding: 0;
     }
 }
 @media screen and (width: 1024px) {
     #zeropadd{
 overflow-y: none;
     }
 }
 @media screen and (max-width: 576px) {
 
     .side-icon {
         width: 100% !important;
     }
     .side-profile {
         width: 32px !important;
         height: 31px !important;
         margin-right: 15px;
     }
     .input-field input.input {
         background: transparent;
         background-color: transparent;
     }
     #zeropadd{
        padding-left: 10px;
    }
   
 }
 
 