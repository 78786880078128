.skeleton-booking-details .skeleton-item {
    background-color: #e0e0e0;
    animation: pulse 1s infinite ease-in-out;
}
.skeleton-booking-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    padding-left: 20px; 
    border-radius: 1rem;
  }

.skeleton-booking-details .skeleton-header {
    width: 15%;
    height: 5%;
    margin: 8px 0;
    border-radius: 1rem;
}

.skeleton-booking-details .skeleton-bookings1 {
    width: 50%;
    height: 10%;
    border-radius: 1rem;
    margin-top: 2rem;
}

.skeleton-booking-details .skeleton-bookings2 {
    width: 60%;
    height: 10%;
    border-radius: 1rem;
    margin-top: 2rem;
}
.skeleton-booking-details .skeleton-bookings3 {
    width: 70%;
    height: 10%;
    border-radius: 1rem;
    margin-top: 2rem;
}
.skeleton-booking-details .skeleton-bookings4 {
    width: 80%;
    height: 10%;
    border-radius: 1rem;
    margin-top: 2rem;
}
.skeleton-booking-details .skeleton-bookings5 {
    width: 90%;
    height: 10%;
    border-radius: 1rem;
    margin-top: 2rem;
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }

    50% {
        background-color: #d3d3d3;
    }

    100% {
        background-color: #e0e0e0;
    }
}


