.submit-btn button {
    width: 100%;
    height: 40px;
    border: 1.6px solid black;
    border-radius: 7px;
    background: #ffffff;
    color: #585454;
    
}

@media only screen and (max-width: 768px) {
    .navbar .navbar-toggler {
      width: auto;
      height: auto;
    }
    .top-nav .container-fluid {
        position: absolute;
        top: 0;
        width: 100% !important;
        height: auto;
    }
    .top-nav nav.navbar {
        height: 70px;
    }

  }


