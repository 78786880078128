.balance_1 p {
    font-size: 16px;
}

#jobfont {
    font-weight: none;
}

@media (max-width:480px) {
    .balance_1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .red {
        margin-left: 50px;
    }
}


.skeleton-job-details .skeleton-item {
    background-color: #e0e0e0;
    animation: pulse 1s infinite ease-in-out;
}
.skeleton-job-details {
    width: 290px;
    height: 70vh;
    padding-left: 20px; 
    border-radius: 1rem;
  }

.skeleton-job-details .skeleton-header {
    width: 85%;
    height: 50px;
    margin: 8px 0;
    border-radius: 1rem;
}

.skeleton-job-details .skeleton-balance1 {
    width: 60%;
    height: 50px;
    margin-bottom: 1rem;
    margin-top: 3rem;
    border-radius: 1rem;
}

.skeleton-job-details .skeleton-balance {
    width: 60%;
    height: 50px;
    margin-bottom: 1rem;
    border-radius: 1rem;
}

.skeleton-job-details .skeleton-balance-item {
    width: 30%;
    height: 20px;
    margin-bottom: 2rem;
    border-radius: 1rem;
}

.skeleton-job-details .skeleton-button1 {
    width: 65%;
    height: 168px;
    margin-bottom: 1rem;
    margin-top: 3rem;
    border-radius: 2rem;
}

.skeleton-job-details .skeleton-button {
    margin-top: 20px;
    width: 80%;
    height: 40px;
    border-radius: 8px;
    border-radius: 1rem;
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }

    50% {
        background-color: #d3d3d3;
    }

    100% {
        background-color: #e0e0e0;
    }
}

/* Media queries for responsiveness */

@media (max-width: 480px) {
    .skeleton-job-details {
        height: 60vh;
      }

    .skeleton-job-details .skeleton-balance1 {
        margin-top: 1rem;
    }

    .skeleton-job-details .skeleton-balance-item {
        margin-bottom: 1rem;
    }

    .skeleton-job-details .skeleton-button1 {
        margin-top: 1rem;
        height: 130px;
    }   
}

