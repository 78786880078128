/* Header container styles */
.custom-header-container1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff; /* Changed background to white */
  border-bottom: 1px solid #E2E8F0;
  height: 131px;
}

/* User details container */
.custom-user-container1 {
  display: flex;
  align-items: center;
}

/* Avatar styles */
.custom-avatar {
  width: 2.5rem; /* 40px */
  height: 2.5rem; /* 40px */
  border-radius: 50%;
  margin-right: 16px; /* 1rem */
  object-fit: cover;
}

/* User name styles */
.custom-user-name {
  font-size: 16px; 
  font-weight: 600;
  color: #000;
}
/* User status styles */
.custom-user-status {
  font-size: 0.875rem; /* 14px */
}

/* Last seen text style */
.custom-last-seen {
  font-size: 0.75rem; /* 12px */
  color: #6b7280; /* Tailwind's gray-600 */
  margin-top: 4px;
}

/* Options button styles */
.custom-optionsbtn {
  padding: 8px; /* 0.5rem */
  background-color: #16a34a; /* Tailwind's green-600 */
  color: white;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-optionsbtn:hover {
  background-color: #f3f4f6; /* Tailwind's gray-100 */
}
