
  body {
    background-color: white;
  }
  
  .about {
    margin-left: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 1.5rem;
  }
  .abouts {
    margin-left: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 1.5rem;
   
  }
  .about-img img {
    padding-bottom: 20%;
    max-width: 500px;
    height: auto;
    width: 100%;
    margin-left: 70px;
    border-radius: 30% 70% 70% 30% / 30% 31% 69% 70%;
  }
  
  .about-text h2 {
    font-size: 60px;
    color: black;
  }
  
  .about-text h2 span {
    color: #F15A29;
  }
  
  .about-text h4 {
    font-size: 29px;
    font-weight: 600;
    color: blue;
    line-height: 1.7;
    margin: 15px 0 30px;
  }
  
  .about-text p {
    color: black;
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 4rem;
  }
  
  @media (max-width: 1024px) {
    .about {
        grid-template-columns: repeat(1, 1fr);
        margin-left: 0;
        gap: 1rem;
    }
    .abouts {
      display: flex;
      flex-direction: column-reverse;
      /* grid-template-columns: repeat(1, 1fr); */
      margin-left: 0;
      gap: 1rem;
  }
  
    .about-img img {
        margin-left: 0;
        max-width: 400px;
        width: 80vw;
    }
  
    .about-text h2 {
        font-size: 50px;
    }
  
    .about-text h4 {
        font-size: 25px;
    }
  
    .about-text p {
        font-size: 18px;
    }
  }
  
  @media (max-width: 768px) {
    .about {
        grid-template-columns: repeat(1, 1fr);
    }
  
    .about-img img {
        margin-left: 0;
        max-width: 350px;
        width: 90vw;
    }
  
    .about-text h2 {
        font-size: 40px;
    }
  
    .about-text h4 {
        font-size: 23px;
    }
  
    .about-text p {
        font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .about{
      border-bottom: 1px solid rgba(128, 128, 128, 0.304);
    }
    .about-img img {
        max-width: 500px;
        width: 100%;
        padding-bottom: 10%;
    }
  
    .about-text h2 {
        font-size: 35px;
        margin-left: 10px;
    }
  
    .about-text h4 {
        font-size: 20px;
        margin-left: 10px;
    }
  
    .about-text p {
        font-size: 14px;
        margin-left: 10px;
    }
  }
  
  @media (max-width: 320px) {
    .about-img img {
        max-width: 250px;
        width: 100%;
        padding-bottom: 5%;
    }
  
    .about-text h2 {
        font-size: 30px;
    }
  
    .about-text h4 {
        font-size: 18px;
    }
  
    .about-text p {
        font-size: 12px;
    }
  }
  