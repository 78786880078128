.custom-flex {
  display: flex;
}

.custom-my {
  margin-top: 8px;
  margin-bottom: 8px;
}

.custom-inline-block {
  display: inline-block;
}

.custom-padding {
  padding: 12px;
}

.custom-rounded {
  border-radius: 0.5rem; /* 8px */
}

.custom-bg-blue {
  color: #000;
}

.colorvalap {
  color: #64748B !important;
}

.custom-bg-gray {
  color: #000;
}

.custom-img-size {
  width: 128px; /* 8rem */
  height: 128px; /* 8rem */
  object-fit: cover;
  border-radius: 0.25rem; /* Optional: to match overall style */
}

.custom-block {
  display: block;
}

.custom-text-xs {
  font-size: 12px; /* 0.75rem */
}

.custom-text-gray {
  color: #fff; /* Tailwind's gray-500 */
}

.custom-justify-end {
  justify-content: flex-end;
}

.custom-justify-start {
  justify-content: flex-start;
}

.custom-max-w {
  max-width: 50%; /* 20rem */
}

.bubble-wrapper {
  display: flex;
  margin: 10px;
}

.align-left {
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
}

.message-bubble {
  max-width: 60%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  word-wrap: break-word; /* Ensure long text breaks */
  word-break: break-word; /* Support breaking long words */
}

.msgwithtym {
  display: flex;
  justify-content: space-between; /* Ensure proper spacing between message and timestamp */
  align-items: flex-end; /* Align timestamp to the bottom */
}

.message-bubble.own {
  background-color: #eaedefff;
  color: #64748B !important;
  box-shadow: 0 2px 5px rgba(141, 162, 249, 0.908);
}

.message-bubble.other {
  background-color: #eaedef00;
  color: #64748B !important;
}

.message-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-name {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.timestamp {
  font-size: 12px;
  color: #888; /* More subtle color for timestamp */
  white-space: nowrap; /* Prevent timestamp from wrapping */
}

.message-text {
  margin: 0;
  font-size: 14px;
  flex: 1; /* Ensure the message text takes up available space */
}

#timen {
  margin-left: 10px; /* Reduced spacing for better alignment */
}

@media (max-width: 480px) {
  .message-bubble {
    max-width: 90%; /* Adjust for smaller screens */
  }
}
