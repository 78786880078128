.myListings {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 60px 50px;
}

.booking-iocn label img {
  width: 26px;
}

.booking-iocn label img {
  width: 26px;
  float: right;
  margin-top: 7px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .myListings {
    padding: 0 20px;
  }
}

.myListings-2 {
  display: flex;
  width: 100%;
  max-width: 1217px;
  flex-direction: column;
}

.all-book a {
  margin: 19px 25px;
}

.active-book a {
  margin: 19px 25px;
  display: block;

}

.active-book {
  border-bottom: 1px solid rgba(226, 232, 240, 1);

}

@media (max-width: 768px) {
  .myListings-2 {
    max-width: 100%;
  }
}

.myListings-3 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 768px) {
  .myListings-3 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

.myListings-4 {
  align-self: stretch;
  display: flex;
  gap: 9px;
  margin: auto 0;
  padding: 0 1px;
}

.img {
  aspect-ratio: 1.22;
  object-fit: auto;
  object-position: center;
  width: 39px;
}

.img-2 {
  aspect-ratio: 7.69;
  object-fit: auto;
  object-position: center;
  width: 155px;
  max-width: 100%;
  margin: auto 0;
}

.myListings-5 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin: auto 0;
}

@media (max-width: 768px) {
  .myListings-5 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

.myListings-6 {
  font-family: Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
  flex-grow: 1;
}

.myListings-7 {
  font-family: Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
  flex-grow: 1;
  flex-basis: auto;
}

.myListings-8 {
  font-family: Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.myListings-9 {
  color: #f15a29;
  font-family: Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
  flex-grow: 1;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .myListings-9 {
    white-space: initial;
  }
}

.myListings-10 {
  align-self: stretch;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  white-space: nowrap;
  padding: 0 1px;
}

@media (max-width: 768px) {
  .myListings-10 {
    white-space: initial;
  }
}

.img-3 {
  aspect-ratio: 2.78;
  object-fit: auto;
  object-position: center;
  width: 66px;
  align-self: stretch;
  margin: auto 0;
}

.myListings-11 {
  font-family: Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
  border-radius: 10px;
  border: 1.5px solid #000;
  flex-grow: 1;
  justify-content: center;
  padding: 18px 12px;
}

@media (max-width: 768px) {
  .myListings-11 {
    white-space: initial;
  }
}

.img-4 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 49px;
  border-radius: 50%;
}

.myListings-12 {
  background-color: #f15a29;
  align-self: center;
  width: 91px;
  height: 8px;
  margin: 23px 0 0 87px;
}

.myListings-13 {
  display: flex;
  margin-top: 48px;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 0 1px;
}

.main-wid {
  height: 100vh;
}

@media (max-width: 768px) {
  .myListings-13 {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .myListings-14 {
    max-width: 100%;
  }

}

.myListings-15 {
  gap: 20px;
  display: flex;
}

@media (max-width: 768px) {
  .myListings-15 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 21%;
  margin-left: 0px;
}

@media (max-width: 768px) {
  .column {
    width: 100%;
    display: flex;
    margin-left: 1.8rem;
    align-items: center;
  }
}

.img-5 {
  aspect-ratio: 1.02;
  object-fit: auto;
  object-position: center;
  width: 116px;
  border-radius: 50%;
  max-width: 100%;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .img-5 {
    margin-top: 16px;
  }
}

.column-2 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 57%;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .column-2 {
    width: 100%;
    display: flex;
    text-align: center;

  }
}

.myListings-16 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin: auto 0;
}

.myListings-17 {
  color: var(--Neutral-Gray-900, #0f172a);
  letter-spacing: -0.46px;
  font: 700 23px/122% Plus Jakarta Sans, -apple-system, Roboto,
    Helvetica, sans-serif;
}

.myListings-18 {
  color: var(--Neutral-Gray-500, #64748b);
  margin-top: 16px;
  white-space: nowrap;
  font: 400 14px/121% Plus Jakarta Sans, -apple-system, Roboto,
    Helvetica, sans-serif;
}

@media (max-width: 768px) {
  .myListings-18 {
    white-space: initial;
  }

}

.myListings-19 {
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  gap: 2px;
  font-size: 17px;
  color: var(--Neutral-Gray-400, #94a3b8);
  font-weight: 600;
  white-space: nowrap;
  line-height: 150%;
}

@media (max-width: 768px) {
  .myListings-19 {
    white-space: initial;
  }
}

.img-6 {
  aspect-ratio: 5;
  object-fit: auto;
  object-position: center;
  width: 116px;
  max-width: 100%;
}

.myListings-20 {
  font-family: Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
  flex-grow: 1;
  flex-basis: auto;
}

.column-3 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 23%;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .column-3 {
    width: 100%;
    margin-top: -22px;
  }
}

.myListings-21 {
  border-radius: 10px;
  border: 1px solid var(--Neutral-Gray-900, #0f172a);
  justify-content: center;
  align-self: stretch;
  color: var(--Neutral-Gray-900, #0f172a);
  white-space: nowrap;
  width: 100%;
  margin: auto 0;
  padding: 15px 16px;
  font: 600 14px/121% Plus Jakarta Sans, -apple-system, Roboto,
    Helvetica, sans-serif;
}

@media (max-width: 768px) {
  .myListings-21 {
    white-space: initial;
    margin-top: 40px;
  }
}

.myListings-22 {
  border-radius: 5px;
  /* background-color: var(--Neutral-Gray-900, #0f172a); */
  justify-content: center;
  color: black;
  white-space: nowrap;

  margin-top: 20px;
  width: 150px;

  padding: 16px 29px;
  font: 600 18px/122% Plus Jakarta Sans, -apple-system, Roboto,
    Helvetica, sans-serif;
}

@media (max-width: 768px) {
  .myListings-22 {
    padding: 20px;
    width: 120px;

    display: flex;
    justify-content: start;




  }
}

.img-7 {
  object-fit: auto;
  object-position: center;
  width: 100%;
  stroke-width: 1px;
  stroke: var(--Neutral-Gray-200, #e2e8f0);
  margin-top: 64px;
}

@media (max-width: 768px) {
  .img-7 {
    max-width: 100%;
    margin-top: 40px;
  }
}

.myListings-23 {
  display: flex;
  margin-top: -10rem;
  justify-content: space-between;
  gap: 20px;
  white-space: nowrap;
  padding: 0 1px;
}

@media (max-width: 768px) {
  .myListings-23 {
    max-width: 100%;
    margin-top: -10rem;
    flex-wrap: wrap;
    white-space: initial;
  }
}

.myListings-24 {
  color: var(--Neutral-Gray-900, #0f172a);
  letter-spacing: -0.38px;
  align-self: end;
  margin-top: 21px;
  font: 700 19px/121% Plus Jakarta Sans, -apple-system, Roboto,
    Helvetica, sans-serif;
}

.myListings-25 {
  border-radius: 10px;
  background-color: #f15a29;
  justify-content: center;
  color: #fff;

  padding: 16px 20px;
  font: 600 16px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 768px) {
  .myListings-25 {
    white-space: initial;


  }
}

.card-deck {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 35px;
  flex-wrap: wrap;
  justify-content: center;

}

.card {
  width: 22rem;
}

@media (max-width: 576px) {
  .booking-iocn {
    width: 55% !important;
  }

  .booking-iocn label {
    font-size: 18px !important;
    padding: 20px 18px !important;
    color: #fff !important;
  }

  .active-book a {
    font-size: 18px !important;
    color: #fff !important;
  }

  .booking-iocn label img {
    width: 20px;
    margin-top: 5px;
  }

  .active-book a {
    margin: 5px 0px;
  }

  .active-book a {
    height: auto !important;
  }

  .all-book a {
    margin: 15px 15px 15px 0;
    margin-left: 12px;
  }

  .main-wid {
    overflow-x: scroll;
  }

  .bookinglist label {
    white-space: nowrap;
    width: 100% !important;
    padding: 5px 10px !important;
  }

  .topMain {
    width: 70% !important;
  }

  .bookinglist {
    margin-top: 13.9% !important;
  }

  .booking-iocn {
    display: none;
  }

  .booking-iocn.mobile-nav {
    display: block;
  }
  .all-book a {
    color: #fff !important;
    font-size: 16px !important;
}
}


section {
  input {
    opacity: 0;

    &:checked~div.menu {
      right: -50px;
    }

    &:checked~label.menu__close {
      opacity: 1;
      visibility: visible;
    }
  }

  .menu__button {
    cursor: pointer;
    transition: 0.3s ease;
    box-sizing: border-box;
  }

  .menu__close {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;
    z-index: 1;
  }

  .menu {
    height: 100%;
    width: 350px;
    position: fixed;
    right: -370px;
    top: 0;
    /* background: #B8523D; */
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    z-index: 2;

    .menu__container {
      width: 100%;
      height: 100%;
      display: flex;
      background: #B8523D;

      /* &::before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        top: 15%;
        width: 10%;
        height: 70%;
      } */
    }

    nav {
      width: 100%;
      height: 100%;
      overflow-y: scroll;

      a {
        font-size: 16px;
        border-bottom: 1px solid #A44537;
        transition: 0.3s ease;
        cursor: pointer;
        display: block;
        text-decoration: none;

      }
    }
  }
}

.main-wid .container {
  padding: 6px 16px;
  width: auto;
  display: block;
  position: absolute;
  left: 30px;

}


/* CSS for Skeleton */

.Skeleton-listing{
  display: flex;
  flex-direction: row;
  gap: 3rem;
  width: 100rem;
  height: 20rem;
}

.Skeleton-listing .skeleton-item {
  background-color: #e0e0e0;
  animation: pulse 1s infinite ease-in-out;
}

.Skeleton-listing .skeleton-box {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 20%;
  height: 80%;
  border-radius: 10px;
}


@keyframes pulse {
  0% {
      background-color: #e0e0e0;
  }

  50% {
      background-color: #d3d3d3;
  }

  100% {
      background-color: #e0e0e0;
  }
}


/* Media queries for responsiveness */


@media (max-width: 768px) {
  .Skeleton-listing{
    width: 48rem;
  }
  .Skeleton-listing .skeleton-box {
    width: 24%;
    height: 40%;
  }
}

@media (max-width: 425px) {
  .Skeleton-listing{
    width: 26rem;
  }

  .Skeleton-listing .skeleton-box {
    width: 20%;
    height: 24%;
  }
}

@media (max-width: 375px) {
  .Skeleton-listing{
    width: 20rem;
  }
}