.btn-top {
  display: none;
}
.Become .btn:hover {
  color: #000;
}

ul.navbar-nav {
  margin-left: 80px;
}

ul.navbar-nav .nav-item a:target {
  color: #f26436;
}
.nav-item{
  overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}
img.hind-bs {
  width: 23px;
}

/* Add this to your CSS file, e.g., YourStyles.css */

/* Base styles for icons */
.hind-bs {
  position: relative;
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
}

/* Tooltip styles */
/* .hind-bs::after {
  content: "This feature is under construction";
  visibility: hidden;
  opacity: 0;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -100px;
  transition: opacity 0.3s;
} */

/* Tooltip arrow */
/* .hind-bs::before {
  content: '';
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  transition: opacity 0.3s;
} */

/* Show the tooltip on hover */
.hind-bs:hover::after,
.hind-bs:hover::before {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .main-hd {
      width: 100% !important;
      padding: 0;
  }
  .main-hd .navbar-toggler {
      width: auto !important;
  }
  .main-hd .navbar-nav {
      flex-direction: column !important;
      align-items: flex-end;
  }
  .main-hd .navbar-collapse ul {
      margin-top: 15px;
  }
/*.Become{*/
/*    display: none;*/
/*}*/
/*.Signup{*/
/*    display: none;*/
/*}*/
.btn-top {
  display: block;
}
  .ahost {
      margin-top: 20px;
      margin-left: 20px;
  }
}

/* Bell icon animation */

.bell-animate {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: tada;
  animation-name: tada
}

@-webkit-keyframes tada {
  0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1)
  }

  10%,
  20% {
      -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
  }

  30%,
  50%,
  70%,
  90% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
  }

  40%,
  60%,
  80% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
  }

  100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1)
  }
}

@keyframes tada {
  0% {
      -webkit-transform: scale3d(1, 1, 1);
      -ms-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1)
  }

  10%,
  20% {
      -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
      -ms-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
  }

  30%,
  50%,
  70%,
  90% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
      -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
  }

  40%,
  60%,
  80% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
      -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
  }

  100% {
      -webkit-transform: scale3d(1, 1, 1);
      -ms-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1)
  }
}

/* CSS for Skeleton */

.skeleton-homeheader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

.skeleton-homeheader .skeleton-item {
  background-color: #e0e0e0;
  animation: pulse 1s infinite ease-in-out;
}

.skeleton-homeheader .skeleton-logo {
  width: 350px;
  height: 50px;
  border-radius: 8px;
}

.skeleton-homeheader .skeleton-button {
  width: 200px;
  height: 50px;
  border-radius: 4px;
  margin-left: 2rem;
}

.skeleton-homeheader .skeleton-menu-item {
  width: 215px;
  height: 30px;
  border-radius: 4px;
  margin-left: 4rem;
}


.skeleton-homeheader .skeleton-navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.skeleton-homeheader .skeleton-navbar-items {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.skeleton-homeheader .skeleton-dropdown {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 60rem;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #d3d3d3;
  }
  100% {
    background-color: #e0e0e0;
  }
}

/* Skeleton Responsiveness */


/* Media queries for responsiveness */

@media (min-width: 2560px) {
.skeleton-homeheader .skeleton-logo {
  width: 480px;
  height: 50px;
  border-radius: 8px;
}

.skeleton-homeheader .skeleton-menu-item {
    width: 140px;
    height: 30px;
    margin-left: 10rem;
  }

  .skeleton-homeheader .skeleton-dropdown {
    width: 40px;
    height: 40px;
    margin-left: 80rem;
  }

}
 
@media (max-width: 1440px) {
  .skeleton-homeheader .skeleton-menu-item {
      width: 140px;
      height: 30px;
      margin-left: 1rem;
    }

    .skeleton-homeheader .skeleton-dropdown {
      width: 40px;
      height: 40px;
      margin-left: 30rem;
    }
  
}

@media (max-width: 1200px) {
  .skeleton-homeheader .skeleton-menu-item {
      width: 140px;
    }

    .skeleton-homeheader .skeleton-dropdown {
      margin-left: 15rem;
    }
}

@media (max-width: 1024px) {
  .skeleton-homeheader .skeleton-logo {
      width: 170px;
    }

  .skeleton-homeheader .skeleton-menu-item {
      width: 140px;
    }

    .skeleton-homeheader .skeleton-dropdown {
      margin-left: 25rem;
    }

    .skeleton-homeheader .skeleton-button {
      width: 100px;
      height: 32px;
    }
}

@media (max-width: 768px) {
  .skeleton-homeheader .skeleton-logo {
      width: 170px;
    }

  .skeleton-homeheader .skeleton-menu-item {
      width: 140px;
    }

    .skeleton-homeheader .skeleton-dropdown {
      margin-left: 10rem;
    }

    .skeleton-homeheader .skeleton-button {
      width: 100px;
      height: 32px;
    }
}
 
@media (max-width: 480px) {
  .skeleton-homeheader .skeleton-logo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

  .skeleton-homeheader .skeleton-menu-item {
      width: 70px;
    }

    .skeleton-homeheader .skeleton-dropdown {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-left: 7rem;
    }

    .skeleton-homeheader .skeleton-button {
      width: 100px;
      height: 32px;
    }
}

@media (max-width: 375px) {
  .skeleton-homeheader .skeleton-button {
      width: 40px;
      height: 20px;
      border-radius: 10%;
    }
}

@media (max-width: 320px) {

  .skeleton-homeheader .skeleton-dropdown {
      margin-left: 4rem;
    }
}
 