/* Popup container styling */
.popupContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px;
    background-color: #fff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 1000;
    width: 90%;
    max-width: 500px;
    animation: fadeIn 0.3s ease-in-out;
  }
 
  /* Smooth fade-in animation */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
 
  /* Background overlay */
  .popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 999;
    animation: fadeInOverlay 0.3s ease-in-out;
  }
 
  /* Content inside the popup */
  .popupContent {
    text-align: center;
  }
 
  .popupContent h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
 
  .popupContent p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
 
  .popupContent button {
    padding: 10px 20px;
    width: 10rem;
    font-size: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
 
  .popupContent button:hover {
    background-color: #0056b3;
  }
 
 