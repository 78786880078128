.main-section{
    padding: 1.5rem 0;
}.opt-Located{
    display: flex;
}
#nextbuttonloca{
    width: 10%;
}
@media screen and (max-width: 576px){
    .main-section {
        width: auto !important;
    }
    .opt-Located {
        flex-direction: column !important;
    }
    .min-opt {
        width: 100% !important;
    }
    #nextbuttonloca{
        width: 30%;
    }
    #backbtnloc{
padding-top:0 ;
    }
}
 
 