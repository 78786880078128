/* General styles for the privacy-policy-container */

.privacy-policy-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .intro {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #333;
    padding: 20px;
    width: 100%;
  }
  .intro:last-child {
    border-bottom: none;
  }
  #date{
    text-align: center;
  }
  
  
  .privacy-policy-container h1,
  .privacy-policy-container h2,
  .privacy-policy-container h3 {
    color: #0073e6;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }
  
  .privacy-policy-container h1 {
    font-size: 28px;
  }
  
  .privacy-policy-container h2 {
    font-size: 24px;
    text-align:left;
  }
  
  .privacy-policy-container h3 {
    font-size: 20px;
  }
  
  .privacy-policy-container p,
  .privacy-policy-container ul {
    color: #333;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: justify;
    width: 100%;
  }
  
  .privacy-policy-container ul {
    padding-left: 20px;
  }
  
  .privacy-policy-container ul li {
    list-style-type: disc;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .intro {
        flex-direction: column;
        align-items: flex-start;
      }
    .privacy-policy-container {
      padding: 15px;
    }
  
    .privacy-policy-container h1 {
      font-size: 26px;
    }
  
    .privacy-policy-container h2 {
      font-size: 22px;
      text-align: left;
    }
  
    .privacy-policy-container h3 {
      font-size: 18px;
    }
  
    .privacy-policy-container p,
    .privacy-policy-container ul {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .privacy-policy-container {
      padding: 10px;
    }
  
    .privacy-policy-container h1 {
      font-size: 24px;
    }
  li{
    font-size: 12px;
  }
    .privacy-policy-container h2 {
      font-size: 20px;
    }
  
    .privacy-policy-container h3 {
      font-size: 16px;
    }
  
    .privacy-policy-container p,
    .privacy-policy-container ul {
      font-size: 12px;
    }
  }
  