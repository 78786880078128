.property-slide {
  display: flex;
  /* overflow-x: auto; */
  width: 100%;
}

.property-image {
  width: 31.7%;
  height:400px;
  margin-right: 30px;
  scroll-snap-align: start;
  flex-shrink: 0;
}

.property-slide::-webkit-scrollbar {
  height: 8px;
}

.property-slide::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.property-slide::-webkit-scrollbar-track {
  background: transparent;
}

.property-slide-error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 768px) {
  .property-slide {
    overflow-x: scroll;
    white-space: nowrap;
    display: block;
  }

  .property-image {
    display: inline-block;
    width: 100%;
    /*height: auto;*/
    height: 350px;
    object-fit: cover;
  }
}

/* CSS for Skeleton */

.Skeleton-image-container {
  display: flex;
  /* overflow-x: auto; */
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.Skeleton-image-container .skeleton-images {
  width: 31.7%;
  height:400px;
  margin-right: 30px;
  scroll-snap-align: start;
  flex-shrink: 0;
  background-color: #e0e0e0;
  animation: pulse 1s infinite ease-in-out;
}


@media (max-width: 768px) {
  .Skeleton-image-container {
    overflow-x: scroll;
    white-space: nowrap;
    display: block;
  }

  .Skeleton-image-container .skeleton-images {
    display: inline-block;
    width: 100%;
    /*height: auto;*/
    height: 350px;
    object-fit: cover;
  }
}