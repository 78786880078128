.carousel-container {
  width: 100%;
  margin: auto;
  /* Adjust margin as needed */
}
.list-item-label {
  white-space: nowrap;        
  overflow: hidden;
  text-overflow: ellipsis;    
  width: 100%;              
  display: inline-block;    
}
.carousel-list {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0 auto;
  scrollbar-width: thin;
  scrollbar-color: #56BBFF #f1f1f1;
  transition: all 0.3s ease;
}  
.carousel-list:hover {
  overflow-x: scroll;
}
.carousel-list::-webkit-scrollbar {
  height: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.carousel-list:hover::-webkit-scrollbar {
  opacity: 1;
}
.carousel-list::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 20%;
}
 
.carousel-list::-webkit-scrollbar-track {
  background-color: #fff;
}
 
.Crouseldisplay {
  display: flex;
  align-items: center;
}
 
.list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  outline: none;
  margin-right: 15px;
}
 
#flexvoloum{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 24px;
}
 
.list-item-img {
  width: 35px;
  height: 35px;
}
 
.list-item-label {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
 
.background-image {
  width: 100%;
  height: auto;
}
 
.content-section {
  background-image: url(../../assets/Rectangle1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px;
}
 
.main-title {
  color: #ffffff;
  font-size: 50px;
  padding: 1.3rem;
  text-align: start;
  margin-top: 3.4rem;
  margin-bottom: 1.4rem;
  margin-left: 2.1rem;
}
 
.filter-container {
  display: flex;
}
 
.filter-container {
  display: flex;
  width: 90%;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 10px;
  align-items: center;
  padding: 8px 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: auto;
}
 
.filter-item {
  /* flex: 1 ; Adjust flex basis to fit the layout */
  margin-bottom: 10px;
  padding: 0 10px;
  width: 200px;
}
 
.filter-item2 {
  /* flex: 1; Adjust flex basis to fit the layout */
  margin-bottom: 10px;
  padding: 0 10px;
  width: 200px;
}
 
.filter-item input {
  width: 120px;
}
 
.check-in,
.check-out {
  border-left: 1px solid #b8b8b8;
}
 
.select-container {
  display: flex;
  margin-top: 6px;
}
 
.select-img {
  width: 23px;
  height: 23px;
}
 
.input-field2 {
  border: 1px solid rgb(217, 214, 214);
  padding: 1%;
  /*margin-top: 10px;*/
  border-radius: 6px
}
 
.select-box,
.input {
  width: 100%;
  border: none;
  outline: none;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 14px;
  line-height: 17.64px;
  color: rgb(147, 145, 145);
  margin-top: 0px;
  background: white;
  border: 1px solid rgb(217, 214, 214);
  padding: 5%;
  border-radius: 6px;
  height: auto;
 
}
 
.select-box-country {
  width: 100%;
  height: -130px;
  border: none;
  outline: none;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 14px;
  line-height: 17.64px;
  margin-top: -3px;
  background: transparent;
}
 
.search-button {
  background-color: #F15A29;
  border-radius: 25px;
  height: 50px;
  width: 50px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
 
.search-icon {
  width: 25px;
  height: 25px;
}
 
.span-spacing {
  margin-right: 20px;
 
  white-space: nowrap;
}
 
.guest-stay-container {
  width: 135px;
  position: relative;
}
 
.guest-box {
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 20px;
  z-index: 1000;
}
 
.guest-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
 
.guest-label {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}
 
.age-range {
  font-size: 12px;
  color: gray;
}
 
.guest-counter {
  display: flex;
  align-items: center;
}
 
.guest-counter button {
  width: 30px;
  height: 30px;
  border: none;
  background: #f0f0f08c;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
  color: black;
}
 
.guest-counter span {
  margin: 0 10px;
  font-size: 16px;
}
.check-in {
  width: 100%;
}
 
.check-in,
.check-out {
  border-left: 1px solid #b8b8b8;
  width: 100%;
}
.advance-filter-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 3.3%;
}
.filter-button {
  height: 48px;
  width: 200px;
  max-width: 100%;
  border: none;
  border-radius: 12px;
  color: white;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
 
.filter-item input[type=text] {
  background: transparent;
  font-size: 14px;
}
 
.span-spacing {
  margin-right: 20px;
  /* Adjust the margin as needed */
 
  white-space: nowrap;
}
@media (max-width: 768px) {
  .carousel-list {
    overflow: scroll;
  }
 
  .guest-stay-container {
    width: 100%;
  }
 
  .filter-item input {
    width: 180px;
  }
 
  .filter-item2 {
    width: 93%;
    /* border: 3px solid black; */
  }
 
  .filter-item2 input {
    width: 180px;
    /* border: 2px solid red; */
  }
 
  .arrow-icon img {
    display: none;
  }
 
  .carousel-list {
    max-width: 100%;
  }
 
  .filter-container {
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffffa1;
  }
 
  .filter-item span {
    margin-left: 0 !important;
  }
 
  .check-in {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
 
  }
 
  .filter-itemss span {
    margin-left: 0 !important;
  }
 
  .filter-itemss {
    /* border: 1px solid red; */
    /* flex: 1 ;  */
    /* width: 160px; */
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 10px;
    margin-top: 10px;
  }
 
  .arrow-icon img {
    display: none;
  }
 
  .carousel-list {
    max-width: 100%;
  }
 
  .filter-container {
    background-color: #ffffffa1;
  }
 
  .filter-item span {
    margin-left: 0 !important;
  }
 
  .filter-itemss span {
    margin-left: 0 !important;
  }
 
  .filter-itemss {
    /* border: 1px solid red; */
    /* flex: 1 ;  */
    /* width: 160px; */
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 10px;
  }
 
 
}
@media (max-width: 576px) {
  .content-section {
    background-image: url(../../assets/Discover\ Saint\ Lucia.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 20px;
  }
 
  .Crouseldisplay {
    flex-direction: column;
  }
 
  .search-button {
    width: 30%;
  }
  .filter-container {
    width: 90%;
  }
  .filter-item {
    width: 100%;
  }
 .filter-item2 input{
  width: 108%;
  height: 40px;
 }
 .filter-item input{
  width: 100%;
  height: 40px;
 
 }
}
 
 