.skeleton-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
 
.skeleton-circle-row {
    display: flex;
    justify-content: center;
    gap: 122px;
    /* Space between the circles */
    border-bottom: 1px solid #ddd;
}
 
.skeleton-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-top: 2rem;
    margin-bottom: 1rem;
    background-color: #e0e0e0;
    animation: pulse 1s infinite ease-in-out;
}
 
.skeleton-rectangle-box {
    width: 100rem;
    height: 24rem;
    margin-top: 2rem;
    background-color: #e0e0e0;
    animation: pulse 1s infinite ease-in-out;
}
 
.skeleton-second-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    /* Space between each child div */
    padding: 20px;
}
 
.skeleton-row1 {
    display: flex;
    justify-content: center;
    gap: 7rem;
    /* Space between the boxes in the row */
    margin-bottom: 1rem;
}
 
.skeleton-box1 {
    width: 22rem;
    height: 20rem;
    margin-top: 5rem;
    background-color: #e0e0e0;
    animation: pulse 1s infinite ease-in-out;
}
 
.skeleton-row2 {
    display: flex;
    justify-content: center;
    gap: 5rem;
    /* Space between the boxes in the row */
}
 
.skeleton-box2 {
    width: 34rem;
    height: 20rem;
    margin-top: 5rem;
    background-color: #e0e0e0;
    animation: pulse 1s infinite ease-in-out;
}
 
.skeleton-rectangle-box2 {
    width: 100rem;
    height: 15rem;
    margin-top: 5rem;
    background-color: #e0e0e0;
    animation: pulse 1s infinite ease-in-out;
}
 
@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }
 
    50% {
        background-color: #d3d3d3;
    }
 
    100% {
        background-color: #e0e0e0;
    }
}
 
 
@media (min-width: 2560px) {
    .skeleton-circle-row {
        gap: 160px;
    }
 
    .skeleton-circle {
        width: 80px;
        height: 80px;
    }
 
    .skeleton-rectangle-box {
        width: 120rem;
        height: 32rem;
    }
 
    .skeleton-row1 {
        gap: 10rem;
    }
 
    .skeleton-box1 {
        width: 30rem;
        height: 28rem;
    }
 
    .skeleton-box2 {
        width: 40rem;
        height: 35rem;
    }
 
    .skeleton-rectangle-box2 {
        width: 126rem;
        height: 22rem;
    }
 
}
 
 
@media (max-width: 1440px) {
    .skeleton-circle-row {
        gap: 60px;
    }
 
    .skeleton-rectangle-box {
        width: 60rem;
    }
 
    .skeleton-row1 {
        gap: 3rem;
    }
 
    .skeleton-box1 {
        width: 18rem;
        height: 16rem;
    }
 
    .skeleton-box2 {
        width: 24rem;
        height: 16rem;
    }
 
    .skeleton-rectangle-box2 {
        width: 80rem;
        height: 15rem;
    }
 
}
 
@media (max-width: 1200px) {
    .skeleton-circle-row {
        gap: 45px;
    }
 
    .skeleton-rectangle-box {
        width: 60rem;
    }
 
    .skeleton-row1 {
        gap: 4rem;
    }
 
    .skeleton-box1 {
        width: 15rem;
        height: 12rem;
    }
 
    .skeleton-box2 {
        width: 21rem;
        height: 15rem;
    }
 
    .skeleton-rectangle-box2 {
        width: 60rem;
        height: 15rem;
    }
 
}
 
@media (max-width: 1024px) {
    .skeleton-circle-row {
        gap: 10px;
    }
 
    .skeleton-rectangle-box {
        width: 40rem;
        height: 5rem;
    }
 
    .skeleton-row1 {
        gap: 2rem;
    }
 
    .skeleton-box1 {
        width: 10rem;
        height: 8rem;
    }
 
    .skeleton-box2 {
        width: 16rem;
        height: 8rem;
    }
 
    .skeleton-rectangle-box2 {
        width: 40rem;
    }
 
}
 
@media (max-width: 768px) {
    .skeleton-circle-row {
        gap: 20px;
    }
 
    .skeleton-circle {
        width: 40px;
        height: 40px;
    }
 
    .skeleton-rectangle-box {
        width: 40rem;
        height: 15rem;
    }
 
    .skeleton-row1 {
        gap: 50px;
    }
 
    .skeleton-box1 {
        width: 8rem;
        height: 6rem;
    }
 
    .skeleton-box2 {
        width: 8rem;
        height: 8rem;
    }
 
    .skeleton-rectangle-box2 {
        width: 40rem;
    }
 
}
 
@media (max-width: 425px) {
    .skeleton-circle-row {
        gap: 16px;
    }
 
    .skeleton-circle {
        width: 20px;
        height: 20px;
    }
 
    .skeleton-rectangle-box {
        width: 20rem;
        height: 14rem;
    }
 
    .skeleton-row1 {
        gap: 2rem;
    }
 
    .skeleton-box1 {
        margin-top: 1rem;
        width: 40px;
        height: 40px;
    }
 
    .skeleton-box2 {
        margin-top: 1rem;
        width: 60px;
        height: 60px;
    }
 
    .skeleton-rectangle-box2 {
        margin-top: 1rem;
        width: 20rem;
        height: 2rem;
    }
 
}
 
@media (max-width: 375px) {
    .skeleton-circle-row {
        gap: 16px;
    }
 
    .skeleton-circle {
        width: 20px;
        height: 20px;
    }
 
    .skeleton-rectangle-box {
        width: 20rem;
        height: 14rem;
    }
 
    .skeleton-row1 {
        gap: 2rem;
    }
 
    .skeleton-box1 {
        margin-top: 1rem;
        width: 40px;
        height: 40px;
    }
 
    .skeleton-row2 {
        gap: 2rem;
    }
 
    .skeleton-box2 {
        margin-top: 1rem;
        width: 60px;
        height: 60px;
    }
 
    .skeleton-rectangle-box2 {
        margin-top: 1rem;
        width: 20rem;
        height: 2rem;
    }
 
}
 
@media (max-width: 320px) {
    .skeleton-circle-row {
        gap: 8px;
    }
 
    .skeleton-circle {
        width: 20px;
        height: 20px;
    }
 
    .skeleton-rectangle-box {
        margin-top: 1rem;
        width: 16rem;
        height: 15rem;
    }
 
    .skeleton-row1 {
        gap: 1rem;
    }
 
    .skeleton-box1 {
        margin-top: 1rem;
        width: 50px;
        height: 50px;
    }
 
    .skeleton-row2 {
        gap: 2rem;
    }
 
    .skeleton-box2 {
        margin-top: 1rem;
        width: 60px;
        height: 100px;
    }
 
    .skeleton-rectangle-box2 {
        margin-top: 1rem;
        width: 10rem;
        height: 5rem;
    }
 
}
 