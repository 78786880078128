.booking-history {
  display: flex;
  width: 100%;
  margin: 0 auto;
}
 
.sidebar1 {
  min-width: 200px;
  border-right: 2px solid #E2E8F0;
  transition: transform 0.3s ease-in-out;
}
 
.sidebar1-header {
  font-size: 23px;
  font-weight: 600;
  color: #0F172A;
  border-bottom: 1px solid #E2E8F0;
  padding: 5px 72px 9px 50px;
}
 
.sidebar1-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 42px;
}
.menu-item {
  display: flex;
  width: 70%;
  height: 30px;
  font-size: 14px;
  margin-top: 15px;
  font-weight: 600;
  color: #0F172A;
  padding: 4px 8px;
  cursor: pointer;
  border-left: 4px solid transparent;
  transition: border-left-color 0.3s;
  white-space: nowrap;
}
 
 
.menu-item.active {
  border-left-color: #F15A29;
}
 
.content {
  width: 80%;
  /* max-width:1200px ; */
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}
 
.bars-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  margin: 10px;
}
 
@media (max-width: 768px) {
  .booking-history {
    flex-direction: column;
    position: relative; /* Ensures the sidebar is positioned relative to the container */
  }
 
  .sidebar1 {
    width: 100%;
    border-right: none;
    border-bottom: none;
    position: absolute; /* Position the sidebar over the content */
    top: 0;
    left: 0;
    transform: translateX(-100%);
    background-color: white; /* Set a background color to distinguish the sidebar */
    z-index: 1000; /* Ensures the sidebar is above the content */
    height: 100vh; /* Ensure the sidebar covers the entire height */
  }
 
  .sidebar1.open {
    transform: translateX(0);
  }
 
  .content {
    width: 100%;
    padding: 10px;
    max-height: none; /* Remove the max height for mobile content */
  }
 
  .bars-icon {
    display: block;
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 999; /* Ensures the icon is above the sidebar */
  }
}
 
 
 