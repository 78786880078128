.pay-heading label {
    font-size: 16px;
    font-weight: 600;
}

.Payment-Option{
    width: 60%;
    padding: 1.5rem 0;
    padding-left: 20px;
}

@media screen and (max-width: 576px) {
    .main-section {
        display: block !important;
    }
    .side-section {
        width: 100% !important;
    }
    .Payment-Option {
        width: 100%;
        padding: 1.5rem 0;
        padding-left: 20px;
    }
    .First-Name {
        display: block !important;
    }
    .sec-section {
        padding-right: 15px;
    }
    .lastname {
        margin-left: 0 !important;
    }
    .code-secu {
        margin-left: 0 !important;
    }
}

