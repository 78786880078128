/* Chat item container styles */
.custom-chat-item {
  display: flex;
  align-items: center;
  padding: 12px; /* 0.75rem */
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #E2E8F0;
  position: relative; /* Allows for positioning the timestamp at the top */
}

.custom-chat-item:hover {
  background-color: #e5e7eb; /* Tailwind's gray-200 */
}

/* Avatar styles */
.custom-avatar {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-right: 16px;
  object-fit: cover;
}

/* Chat name styles */
.custom-chat-name {
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 23px;
}

/* Last message styles */
.custom-last-message {
  font-size: 0.875rem; /* 14px */
  color: #4b5563; /* Tailwind's gray-600 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Timestamp styles */
.custom-timestamp {
  position: absolute;
  top: 15px; /* Move timestamp to the top */
  right: 16px; /* Align with the padding of the avatar */
  font-size: 0.75rem; /* 12px */
  color: #9ca3af; /* Tailwind's gray-400 */
  white-space: nowrap;
}
@media (max-width: 480px) {
.custom-chat-name {
  line-height:12px;
}}