/* Sidebar container scoped to the #sidebar */
#sidebar .sidebar {
  width: 25%;
  padding: 20px;
  background-color: #f8f9fa;
  text-align: left;
  transition: all 0.3s ease-in-out;
}
.withdrorow {
  display: flex;
  justify-content: space-between;
}
.withdrocolumn {
  width: 40%;
}
.withdrocolumnbtn button {
  width: 10em;
}
.withdrorowbtn {
  display: flex;
  gap: 20%;
}
.custom-selectop {
  width: 40%;
}
.custom-option {
  white-space: normal;
}
/* Flex layout for methods icons (PayPal & User Plus) scoped to the #sidebar */
#sidebar .methods {
  display: flex;
  gap: 15px;
  justify-content: start;
  align-items: center;
}

/* Styling for the earning content scoped to the #sidebar */
#sidebar .earningcontent {
  margin-left: 50px;
}

/* Balance section scoped to the #sidebar */
#sidebar .balance h1 {
  color: #047857;
}

/* Spacing and styling for balance and withdraw method sections scoped to the #sidebar */
#sidebar .balance,
#sidebar .withdraw-method {
  line-height: 1.5;
  margin-bottom: 20px;
}

.popup-overlaybank786,
.popup-overlay786 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it stays above all elements */
}

/* Popup content styling scoped to the #sidebar */
#sidebar .popup-content786 {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.popup-contentbank786,
.popup-content786 {
  max-width: 90%;
  max-height: 90%; /* Prevent overflow from the screen */
  overflow-y: auto; /* Add scroll if content overflows */
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: relative;
}

#sidebar .popup-content786 h2 {
  font-size: 16px;
  margin-bottom: 20px;
}

/* Success message styling scoped to the #sidebar */
#sidebar .success-message {
  color: green;
  margin-top: 10px;
}

/* Flexbox for form buttons scoped to the #sidebar */
#sidebar .ff {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}

/* Styling for the input fields scoped to the #sidebar */
#sidebar input[type="text"],
#sidebar input[type="email"],
#sidebar input[type="number"],
#sidebar select {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border-radius: 5px;
}

#sidebar input[type="checkbox"] {
  margin-right: 8px;
}

#sidebar button {
  background: transparent;
  color: black;
  border: 1px solid black;
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
}

#sidebar button:hover {
  background-color: #f1f1f1;
}

/* Popup content styling scoped to the #sidebar */
#sidebar .popup-contentbank786 {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#sidebar .popup-contentbank786 h2 {
  font-size: 16px;
  margin-bottom: 20px;
}

#sidebar .popup-contentbank786 label {
  font-size: 12px;
  margin-bottom: 10px;
}

#sidebar .popup-contentbank786 h2 {
  font-size: 13px;
  margin-bottom: 15px;
}

/* CheckBox */
#sidebar .checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Add spacing between rows */
}

#sidebar .checkbox-item {
  display: flex;
  align-items: center;
  gap: 10px; /* Add spacing between the checkbox and the label */
}

#sidebar .checkbox-item input[type="checkbox"] {
  margin: 0; /* Remove default margin from checkboxes */
}
.earningcontent h2,h1,h4{
  font-size: 20px !important;
}
.earningcontent p{
  font-size: 14px !important;
}
@media (min-width: 1024px) {
  #sidebar .sidebar {
    width: 25%;
  }
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
  #sidebar .sidebar {
    width: 40%;
  }
}

/* Small screens (phones) */
@media (max-width: 768px) {
  #sidebar .sidebar {
    width: 100%;
    padding: 10px;
  }

  #sidebar {
    margin-left: 0;
  }

  #sidebar .balance h1 {
    font-size: 10px;
  }

  #sidebar .methods {
    justify-content: center;
    gap: 20px;
  }

  #sidebar .popup-content786,
  #sidebar .popup-contentbank786 {
    padding: 15px;
    width: 90%;
  }

  #sidebar .popup-content786 h2,
  #sidebar .popup-contentbank786 h2 {
    font-size: 14px;
  }

  #sidebar .ff {
    flex-direction: column;
    gap: 15px;
  }

  #sidebar .withdraw-method {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  #sidebar .withdraw-method h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .popup-contentbank786,
  .popup-content786 {
    max-width: 95%;
    max-height: 90%; /* Adjust for smaller viewports */
    padding: 15px;
  }
  .withdrorowbtn {
    display: flex;
    gap: 2%;
  }
}

/* Extra small screens (small mobile devices) */
@media (max-width: 480px) {
  #sidebar .sidebar {
    width: 100%;
    padding: 5px;
  }

  #sidebar .balance h1 {
    font-size: 16px;
  }

  #sidebar .methods {
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  #sidebar .popup-content786,
  #sidebar .popup-contentbank786 {
    max-width: 77%;
    padding: 10px;
  }

  #sidebar .popup-content786 h2,
  #sidebar .popup-contentbank786 h2 {
    font-size: 13px;
    margin-bottom: 15px;
  }

  #sidebar button {
    padding: 6px 10px;
    font-size: 11px;
  }
  #sidebar .earningcontent {
    margin-left: 5px;
  }
  .earningcontent h2,h1,h4{
    font-size: 14px !important;
  }
  .earningcontent p{
    font-size: 12px !important;
  }

  .withdrorow {
    flex-direction: column;
    justify-content: center;
  }

  .withdrocolumn {
    width: 90%;
  }

  .withdrorowbtn {
    flex-direction: column;
    gap: 10px;
  }
  .popup-contentbank786,
  .popup-content786 {
    max-width: 90%;
    max-height: 85%;
    padding: 10px;
  }
}


/* CSS for Skeleton */

.Skeleton-earning-screen{
  display: flex;
  flex-direction: column;
  width: 13rem;
  height: 30rem;
}

.Skeleton-earning-screen .skeleton-item {
  background-color: #e0e0e0;
  animation: pulse 1s infinite ease-in-out;
}

.Skeleton-earning-screen .skeleton-header {
  margin-top: 1rem;
  margin-left: 1rem;
  width: 80%;
  height: 20px;
  border-radius: 4px;
}

.Skeleton-earning-screen .skeleton-content{
  width: 60%;
  height: 3%;
  margin: 8px 0;
  border-radius: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
}


@keyframes pulse {
  0% {
      background-color: #e0e0e0;
  }

  50% {
      background-color: #d3d3d3;
  }

  100% {
      background-color: #e0e0e0;
  }
}


/* Media queries for responsiveness */


@media (max-width: 768px) {
  .Skeleton-earning-screen{
    width: 6rem;
  }
} 

