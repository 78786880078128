.active-booking-list {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}
.content::-webkit-scrollbar {
  display: none;
}
#imgflex {
  display: flex;
  gap: 15px;
}
#userdetaip p {
  margin-bottom: 5px !important;
}
.popupdoc {
  background-color: #000 !important;
}

h3 {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 20px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.custom-table th,
.custom-table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.custom-table th {
  background-color: #f4f4f4;
  color: #333;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-table tbody tr:hover {
  background-color: #f1f1f1;
}

.profile-btn,
.status-btn,
.complete-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.profile-btn:hover,
.status-btn:hover,
.complete-btn:hover {
  background-color: #45a049;
}

.more-options-btn img {
  width: 24px;
  height: 24px;
}

.popup-options {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.cancel-btn,
.upgrade-btn {
  background-color: #f87171;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 5px;
}

.cancel-btn:hover,
.upgrade-btn:hover {
  background-color: #e63946;
}
.previbutton {
  width: 20%;
  background: none;
  border: none;
}
@media (max-width: 480px) {
  #imgflex {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  @media (max-width: 576px) {
    .popupdoc {
        height: auto !important;
    }
}
}
