.review-btn {
    padding: 10px 20px;
    border: none;
    width: auto;
    border-Radius: 4px;
    background-Color: #007bff;
    color: #fff;
}
.review-form-container{
   padding-left: 50px;
}

@media (max-width:480px) {
    .review-btn {
        width: 100%;
    }

    .review-textarea {
        width: 100%;
    }
    .review-form-container{
       padding-left: 10px;
    }
}
